import React, { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
import { baseUrl } from "../../common/common";
import { Dropdown } from "flowbite-react";
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import SplImg from "./images/spl.png";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import {useNavigate,useLocation} from "react-router-dom";
import { Tooltip } from 'react-tooltip';

export default function AddProgram({ newProgramPopupVisible, teachersData, setProgramsData, newProgramAddUpdateStatus, setNewProgramAddUpdateStatus, setNewProgramPopupVisible ,setCreateNewProgramStatus}) {
    
    const location = useLocation();
    const navigate = useNavigate();

    const fetchEditProgramDetails = async () => {
        try {
          axios({
            method: "post",
            url: baseUrl + "/api/get_programs",
            data: {
              school_id: localStorage.getItem("schoolId"),
              id: localStorage.getItem('programId')
            },
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": localStorage.getItem('access_token')
            },
          }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                const programData = response.data.data[0];
              
                setTeacher(response.data.teacher);
                setNewProgramName(programData.program_name);
                setProgramStartTime(parseInt(programData.from_time_id));
                setProgramEndTime(parseInt(programData.to_time_id));
              
                // Simplify setting features and additional info
                const updateStatus = (items, itemData) => {
                  const ids = itemData ? itemData.split(',') : [];
                  const updatedItems = { ...items };
              
                  // Set all items to false
                  Object.keys(updatedItems).forEach(key => {
                    updatedItems[key].status = false;
                  });
              
                  // Set matching items to true
                  ids.forEach(id => {
                    const itemKey = Object.keys(items).find(key => items[key].value.toString() === id);
                    if (itemKey) {
                      updatedItems[itemKey].status = true;
                    }
                  });
              
                  return updatedItems;
                };
              
                if (programData.features || programData.additional_info) {
                  setFeatures(updateStatus(features, programData.features));
                  setAdditionalInfo(updateStatus(additionalInfo, programData.additional_info));
                }
              
                if (!programData.features) {
                  setFeatures({
                    food: { status: true, name: 'Food', value: 3 },
                    sleep: { status: true, name: 'Sleep', value: 4 },
                    qrCode: { status: true, name: 'QR Code', value: 2 },
                    attendance: { status: true, name: 'Attendance', value: 5 },
                    leaves: { status: true, name: 'Leaves', value: 6 },
                  });
                }
              
                if (!programData.additional_info) {
                  setAdditionalInfo({
                    inviteParents: {
                      status: false,
                      name: 'Automatically Invite Parents as soon as student profile created',
                      value: 1,
                    },
                  });
                }
              
                setProgramNameError(null);
                setProgramTeacherError(null);
                setTimeError(null);
                $('.overlay').addClass('visible');
                setNewProgramPopupVisible(true);
              }            

          });
        } catch (error) {
          console.error('An error occurred while fetching program details:', error);
          // Optionally, you can set a general error message to state here
        }
    }

    const [programStartTime, setProgramStartTime] = useState("");
    const [programEndTime, setProgramEndTime] = useState("");
    const [programTeacher, setTeacher] = useState("");
    const [timeData, setTimeData] = useState([]);
    const selectedStartTime = timeData.find(data => data.id === programStartTime);
    const selectedEndTime = timeData.find(data => data.id === programEndTime);
    const selectedTeachers = teachersData.find(data => data.id === programTeacher);

    const fetchProgramTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const [newProgramName, setNewProgramName] = useState('');
    const [features, setFeatures] = useState({
        food: { status: true, name: 'Food', value: 3 },
        sleep: { status: true, name: 'Sleep', value: 4 },
        qrCode: { status: true, name: 'QR Code', value: 2 },
        attendance: { status: true, name: 'Attendance', value: 5 },
        leaves: { status: true, name: 'Leaves', value: 6 },
        // busTracking: { status: false, name: 'Bus Tracking', value: 7 },
        // cctv: { status: false, name: 'CCTV', value: 8 },
        // fees: { status: false, name: 'Fees', value: 9 }
    });


    const [additionalInfo, setAdditionalInfo] = useState({
        inviteParents: {
            status: true,
            name: 'Automatically Invite Parents as soon as student profile created',
            value: 1
        },
        // addParentChat: {
        //     status: true,
        //     name: 'Add Parent in chat as soon as student profile created',
        //     value: 2
        // },
        // createStudentID: {
        //     status: true,
        //     name: 'Automatically create Student ID as soon as profile created',
        //     value: 3
        // },
    });

    const resetFeaturesAndAdditionalInfo = () => {
        setFeatures({
            food: { status: true, name: 'Food', value: 3 },
            sleep: { status: true, name: 'Sleep', value: 4 },
            qrCode: { status: true, name: 'QR Code', value: 2 },
            attendance: { status: true, name: 'Attendance', value: 5 },
            leaves: { status: true, name: 'Leaves', value: 6 },
            // busTracking: { status: false, name: 'Bus Tracking', value: 7 },
            // cctv: { status: false, name: 'CCTV', value: 8 },
            // fees: { status: false, name: 'Fees', value: 9 }
        });
        setAdditionalInfo({
            inviteParents: {
                status: true,
                name: 'Automatically Invite Parents as soon as student profile created',
                value: 1
            },
            // addParentChat: {
            //     status: true,
            //     name: 'Add Parent in chat as soon as student profile created',
            //     value: 2
            // },
            // createStudentID: {
            //     status: true,
            //     name: 'Automatically create Student ID as soon as profile created',
            //     value: 3
            // }
        })
    }

    const handleFeatureChange = (feature) => {
        setFeatures({
            ...features,
            [feature]: {
                ...features[feature],
                status: !features[feature].status,
            },
        });
    };

    const handleAdditionalInfoChange = (info) => {
        setAdditionalInfo({
            ...additionalInfo,
            [info]: {
                ...additionalInfo[info],
                status: !additionalInfo[info].status,
            },
        });
    };

    const [programeNameError, setProgramNameError] = useState(null);
    const [programeTeacherError, setProgramTeacherError] = useState(null);
    const [timeError, setTimeError] = useState(null);
    const validateProgramForm = () => {
        let valid = true;
        if (newProgramName === "") {
            setProgramNameError("Please enter program name");
            valid = false;
        } else {
            setProgramNameError(null);
        }
        if (programTeacher === "") {
            setProgramTeacherError("Please select program teacher");
            valid = false;
        } else {
            setProgramTeacherError(null);
        }if ((programStartTime === "" || programEndTime === "")||(programStartTime === null || programEndTime === null)){
            setTimeError("Please select class timing");
            valid = false;
          } else {
            setTimeError(null);
          }
        return valid;
    };

    const handleCreateProgram = () => {
        const selectedFeatureValues = Object.keys(features)
            .filter((key) => features[key].status)
            .map((key) => features[key].value);

        const selectedAdditionalInfoValues = Object.keys(additionalInfo)
            .filter((key) => additionalInfo[key].status)
            .map((key) => additionalInfo[key].value);

        const programData = {
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            [newProgramAddUpdateStatus === "add" ? "created_by" : "updated_by"]: localStorage.getItem("loginUserId"),
            ...(newProgramAddUpdateStatus !== "add" && { id: localStorage.getItem("programId") })
        };
        console.log(programData);
        if (validateProgramForm()) {
            axios({
                method: "post",
                url: `${baseUrl}/api/programs_add_edit`,
                data: programData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status) {
                    if (newProgramAddUpdateStatus === "edit") {
                        localStorage.setItem("programName", newProgramName);
                    }else{
                        localStorage.setItem("programId", response.data.id);
                        localStorage.setItem("programName", newProgramName);
                        if(location.pathname=="/student_list"){
                            setCreateNewProgramStatus(true)
                        }else{
                            navigate('/student_list');
                        }
                    }
                    fetchProgramsData();
                    $('.overlay').removeClass('visible');
                    // $('.popup-program-container').removeClass('visible');
                    setNewProgramPopupVisible(false);
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    setNewProgramAddUpdateStatus(null);
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };

    const initialBorderColor = {
        newProgram: ""
    };

    const [borderColor, setBorderColor] = useState(initialBorderColor);

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };

    const handleProgramAddPopupClose = () => {
        setNewProgramPopupVisible(false);
        setNewProgramAddUpdateStatus(null);
        $('.overlay').removeClass('visible');
    };

    const fetchProgramsData = async () => {
        try {
            const postData = {
                school_id: localStorage.getItem("schoolId")
            };
            const { data } = await axios.post(
                baseUrl + "/api/get_programs",
                postData,
                {
                    headers: {
                        "Authorization": localStorage.getItem('access_token')
                    }
                }
            );
            setProgramsData(data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => { }, [snackbarMessage, messageType]);
    useEffect(() => { fetchProgramTimeData() }, []);
    useEffect(() => {
        if (newProgramAddUpdateStatus !== null) {
            if (newProgramAddUpdateStatus === "add") {
                setProgramNameError(null);
                setProgramTeacherError(null);
                setTimeError(null);
                resetFeaturesAndAdditionalInfo();
                setTeacher("");
                setNewProgramName("");
                setProgramStartTime("");
                setProgramEndTime("");
                // setEditProgramVisible(false);
                $('.overlay').addClass('visible');
                setNewProgramPopupVisible(true);
            } 
            if (newProgramAddUpdateStatus === "edit") {
                fetchEditProgramDetails();
            }
        }

    }, [newProgramAddUpdateStatus, newProgramPopupVisible]);

    return (
        <section>
            <Snackbar className={`snack_total ${messageType === 'success' ?"snackbar_success":"snackbar_failure"}`}
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                    <div>
                        {messageType === 'success' ?
                        <span class="icon-List_Present" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>:                    
                        <span class="icon-List_Absent" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                        </span>
                        }
                    </div>
                            {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                // ContentProps={{
                //     sx: {
                //         border: "1px solid",
                //         opacity: "1",
                //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         width: 'auto',
                //         minWidth:'auto'
                //     }
                // }}
                autoHideDuration={3000}
            />
            {
                newProgramPopupVisible && (
                    <div className="popup-program-container visible">
                        <div className="popup-wrap">
                            <h2 className="ft-wt700">{newProgramAddUpdateStatus === "add" ? "Create" : "Update"} New Program</h2>
                            <div className="sp-pop-header ">
                                <div className="form-section-default flexc justifySp fwrap">
                                    <div className="field-label full-field error_mes_cus  pos_stu_cus">
                                        <label>Program Name<span className="col_red">*</span></label>
                                        <div className="">
                                            <input
                                                className={`field-value-holder margin_top10p custamize_color_place  extra_bor_clr  sp-field-input ${programeNameError ? 'error' : ''}`}
                                                // className="sp-field-input"
                                                type="text"
                                                placeholder="Enter Program name"
                                                value={newProgramName}
                                                onChange={(e) => setNewProgramName(e.target.value)}
                                            />
                                        </div>
                                        {programeNameError && <p className="error pos_abo_stu">{programeNameError}</p>}
                                    </div>
                                    <div className={`dis_fle_progra ${teachersData.length<5?"create_program_style":"height_fixed_program"}`}>                                  
                                    <div className="field-label half-field error_mes_cus extra_pad_drop pos_stu_cus ">
                                        <label>Program Teachers<span className="col_red">*</span></label>                                     
                                           <div className="field-value-holder margin_top10p dropbutton pos_stu_cus"
                                            style={{
                                                width: "100%",
                                                borderColor: programeTeacherError ? "red" : borderColor.newProgram,
                                            }}
                                        >
                                            <Dropdown
                                                label={
                                                    <span
                                                        className={`${selectedTeachers ? 'selected-item' : ''}`}
                                                        style={{
                                                            fontSize: "13px",
                                                            justifyContent: "flex-start",
                                                            color: selectedTeachers ? '#1B252F' : '#292929'
                                                        }}
                                                    >
                                                        {selectedTeachers ? selectedTeachers.first_name.slice(0, 20) : "Select Teacher"}</span>}
                                                style={{ width: '100%' }}
                                            >
                                                {teachersData.map(item => (
                                                    <Dropdown.Item
                                                        // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                                                        className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                                            }`} icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                                                        onClick={() => setTeacher(item.id)} 
                                                        key={item.id}
                                                     >
                                                      <div 
                                                        data-tooltip-id={`teacher-tooltip-${item.id}`}
                                                        data-tooltip-content={item.first_name}
                                                        data-tooltip-place="bottom"
                                                        >
                                                        {
                                                          (item.first_name.length <= 20) ? item.first_name : `${item.first_name.slice(0, 20)}...`
                                                        }
                                                        </div>
                                                        {item.first_name.length > 20 && <Tooltip id={`teacher-tooltip-${item.id}`} />}
                                                        {/* {item.first_name} */}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown>
                                        </div>
                                        {programeTeacherError && <p className="error pos_abo_stu">{programeTeacherError}</p>}
                                    </div>
                                    <div className="field-label half-field error_mes_cus extra_date_drop"   >
                                        <label>Class Timing<span className="col_red">*</span></label>
                                        <div className="flexc justifySp">
                                            <div className="half-field">
                                                <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" 
                                                    style={{
                                                        borderColor: timeError && "red",
                                                    }}
                                                >
                                                    <Dropdown
                                                        label={
                                                            <span
                                                                className={`${selectedStartTime ? 'selected-item' : ''}`}
                                                                style={{
                                                                    fontSize: "12px",
                                                                    justifyContent: "flex-start",
                                                                    color: selectedStartTime ? '#1B252F' : '#292929'
                                                                }}
                                                            >

                                                                {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                                                <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                                                {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        {timeData.map(item => (
                                                            <Dropdown.Item

                                                                //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                                                className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                                                    }`}
                                                                icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                                onClick={() => setProgramStartTime(item.id)}
                                                                key={item.id}
                                                            >
                                                                {item.time_name}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="half-field">
                                                <div className="field-value-holder margin_top10p dropbutton cus_drop_siz"
                                                 style={{
                                                     borderColor: timeError && "red",
                                                 }}
                                                  >
                                                    <Dropdown
                                                        label={
                                                            <span
                                                                className={`${selectedEndTime ? 'selected-item' : ''}`}
                                                                style={{
                                                                    fontSize: "13px",
                                                                    fontWeight: "400",
                                                                    lineHeight: "18.2px",
                                                                    textAlign: "left",
                                                                    justifyContent: "flex-start",
                                                                    color: selectedEndTime ? '#1B252F' : '#292929'
                                                                }}
                                                            >
                                                                <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                                                </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        {timeData.map(item => (
                                                            <Dropdown.Item
                                                                // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                                className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                                                    }`}

                                                                icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                                onClick={() => setProgramEndTime(item.id)}
                                                                key={item.id}
                                                            >
                                                                {item.time_name}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        {timeError && <p className="error pos_abo_stu">{timeError}</p>}
                                    </div>
                                    </div>
                                </div>

                                <h3 className="ft-wt600 mt-30">Customise Features</h3>
                                <div className="full-field mb-6">
                                    {Object.keys(features).map((feature) => (
                                        <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                                            <input
                                                type="checkbox"
                                                className="w-4 h-4 text-blue-600 rounded"
                                                checked={features[feature].status}
                                                disabled={
                                                    // (['attendance', 'leaves'].includes(feature))
                                                    // (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                                                    (['qrCode', 'attendance', 'leaves'].includes(feature))
                                                    // features[feature].status=== true
                                                }
                                                onChange={() => handleFeatureChange(feature)}
                                            />
                                            <label className="ms-2 text-sm">{features[feature].name}</label>
                                            {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                                        </div>
                                    ))}
                                </div>
                                <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                                <div className="full-field">
                                    {Object.keys(additionalInfo).map((info) => (
                                        <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                                            <input
                                                type="checkbox"
                                                className="w-4 h-4 text-blue-600 rounded"
                                                checked={additionalInfo[info].status}
                                                onChange={() => handleAdditionalInfoChange(info)}
                                            />
                                            <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="sp-bottom-sticky">
                                <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>{newProgramAddUpdateStatus === "add" ? "Create" : "Update"}</button>
                                <button className="sp-btn-cancel" onClick={() => handleProgramAddPopupClose()}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )
            }

        </section>
    );
}
