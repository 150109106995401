import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dashboardavator1 from "./images/dashboard-avator1.png";
import dashboardavator2 from "./images/dashboard-avator2.png";
import dashboardavator3 from "./images/dashboard-avator3.png";
import dashboardavator4 from "./images/dashboard-avator4.png";
import "../../common/components/js/common.js";
import { baseUrl } from "../../common/common";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import Loader from "react-js-loader";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import run_green from "./images/run_green.svg";
import run_red from "./images/run_red.svg";
import sleep_timer_icon from "./images/sleep_timer_icon.svg";
import lunch_timer_icon from "./images/lunch_timer_icon.svg";
import Chart from "chart.js/auto";
import CommonSidebarFooter from '../../common/layouts/CommonSidebarFooter.jsx';
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";
import { DashboardLogoComponent } from "./Common.jsx";
import ProgramAddComponent from "../programs/AddProgram.jsx";
import Nodata from "../nodata/Nodata.jsx";
import { Tooltip } from 'react-tooltip';

export default function Dashboard() {

  const [schoolDashboard, setSchoolDashboard] = useState([]);
  const [leaveApplied, setLeaveApplied] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [enquiriesData, setEnquiriesData] = useState([]);
  // 12-09-2024 start
  const [refreshNotification, setRefreshNotification] = useState(false);
  const [appliedLeaveStatus, setAppliedLeaveStatus] = useState(false);
  // 12-09-2024 end
  const chartRef = useRef(null);
  const enquiryRef = useRef(null);
  const dashboardRef = useRef(null);
  // 12-09-2024 start
  const targetElementRef = useRef(null);
  // 12-09-2024 end



  useEffect(() => {
    if (chartData.length > 0) {
      renderChart();
    }
    if (enquiriesData.length > 0) {
      renderEnquiriesChart();
    }
  }, [chartData, enquiriesData]);

  // 12-09-2024 start
  useEffect(() => {
    const handleClick = (event) => {
      if (dashboardRef.current && dashboardRef.current.contains(event.target)) {
        setAppliedLeaveStatus(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (appliedLeaveStatus) {
      if (targetElementRef.current) {
        targetElementRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [appliedLeaveStatus]);

  const handleAppliedLeaveStatus = (status) => {
    setAppliedLeaveStatus(status);
  };
  // 12-09-2024 end

  const renderChart = () => {
    if (chartRef.current !== null) {
      chartRef.current.destroy();
    }

    const ctx = document
      .getElementById("studentAttendanceChart")
      .getContext("2d");

    const colors = ["#95A4FC", "#BAEDBD", "#1C1C1C", "#B1E3FF", "#A8C5DA", "#A1E3CB", "#8C8C8C"];
    {/* 26-8-24 */ }
    const maxValue = schoolDashboard.student_count;

    // Create the chart
    chartRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: chartData.map((data) => data.program_name),
        datasets: [
          {
            label: "Attendance",
            data: chartData.map((data) => data.studentAttendanceCount),
            backgroundColor: colors,
            borderColor: colors,
            borderRadius: 8,
            barThickness: 30,
            borderSkipped: false,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: false, /* 26-8-24 */
            min: 1, /* 26-8-24 */
            max: maxValue, /* 26-8-24 */
            grid: {
              display: false,
            },
            border: {
              display: false
            },
            font: {
              size: 12,
              family: 'Inter',
              weight: 400
            },
            color: "var(--black-80, #1C1C1CCC)",
            ticks: {
              font: {
                size: 12,
                family: 'Inter',
                weight: 400
              },
              color: "var(--black-80, #1C1C1CCC)"
            }
          },
          x: {
            grid: {
              display: false,
            },
            border: {
              display: false
            },
            font: {
              size: 12,
              family: 'Inter',
              weight: 400
            },
            color: "var(--black-80, #1C1C1CCC)",
            ticks: {
              font: {
                size: 12,
                family: 'Inter',
                weight: 400
              },
              color: "var(--black-80, #1C1C1CCC)"
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          }
        }

      },
    });
  };

  const renderEnquiriesChart = () => {
    if (enquiryRef.current !== null) {
      enquiryRef.current.destroy();
    }

    const ctx = document
      .getElementById("newEnquiriesChart")
      .getContext("2d");

    const colors = ["#95A4FC", "#BAEDBD", "#1C1C1C", "#B1E3FF", "#A8C5DA", "#A1E3CB", "#8C8C8C"]

    // Create the chart
    enquiryRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: enquiriesData.map((data) => data.program_name),
        datasets: [
          {
            label: "New Enquiry",
            data: enquiriesData.map((data) => data.studentAttendanceCount),
            backgroundColor: colors,
            borderColor: colors,
            borderRadius: 8,
            barThickness: 30,
            borderSkipped: false,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            border: {
              display: false
            },
            font: {
              size: 12,
              family: 'Inter',
              weight: 400
            },
            color: "var(--black-80, #1C1C1CCC)",
            ticks: {
              font: {
                size: 12,
                family: 'Inter',
                weight: 400
              },
              color: "var(--black-80, #1C1C1CCC)"
            }
          },
          x: {
            grid: {
              display: false,
            },
            border: {
              display: false
            },
            font: {
              size: 12,
              family: 'Inter',
              weight: 400
            },
            color: "var(--black-80, #1C1C1CCC)",
            ticks: {
              font: {
                size: 12,
                family: 'Inter',
                weight: 400
              },
              color: "var(--black-80, #1C1C1CCC)"
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          }
        }
      },
    });
  };

  /* // sakthivel 04-05 JULY 2024 start // */
  const getDashboardchartdata = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/student_attendance_chart",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      if (response.data.status === true) {
        console.log("4444444444", response.data.program);
        const existingData = response.data.program;
        const newObjects = [
          {
            "school_id": "1",
            "program_id": 1,
            "program_name": "Day Care",
            "studentcount": 100,
            "studentAttendanceCount": 1
          },
          {
            "school_id": "1",
            "program_id": 2,
            "program_name": "Play group",
            "studentcount": 75,
            "studentAttendanceCount": 11
          },
          {
            "school_id": "1",
            "program_id": 5,
            "program_name": "Nursery",
            "studentcount": 50,
            "studentAttendanceCount": 0
          },
          // {
          //   "school_id": "1",
          //   "program_id": 6,
          //   "program_name": "Junior KG",
          //   "studentcount": 53,
          //   "studentAttendanceCount": 0
          // },
          // {
          //   "school_id": "1",
          //   "program_id": 178,
          //   "program_name": "Sasi",
          //   "studentcount": 2,
          //   "studentAttendanceCount": 2
          // },
          // {
          //   "school_id": "1",
          //   "program_id": 7,
          //   "program_name": "Senior KG",
          //   "studentcount": 230,
          //   "studentAttendanceCount": 0
          // },
        ];
        // const updatedData = [...existingData, ...newObjects];
        const updatedData = [...existingData];
        setChartData(updatedData);
      }
    });
  };


  // const getDashboardchartdata = () => {
  //   axios({
  //     method: "post",
  //     url: baseUrl + "/api/student_attendance_chart",
  //     data: {
  //       school_id: localStorage.getItem("schoolId"),
  //     },
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       "Authorization": localStorage.getItem('access_token')
  //     },
  //   }).then((response) => {
  //     if (response.data.status === true) {
  //       console.log("4444444444", response.data.program);
  //       setChartData(response.data.program);
  //     }
  //   });
  // };

  // const getDashboardEnquirydata = () => {
  //   axios({
  //     method: "post",
  //     url: baseUrl + "/api/student_attendance_chart",
  //     data: {
  //       school_id: localStorage.getItem("schoolId"),
  //     },
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       "Authorization": localStorage.getItem('access_token')
  //     },
  //   }).then((response) => {
  //     if (response.data.status === true) {
  //       console.log("4444444444", response.data.program);
  //       setEnquiriesData(response.data.program);
  //     }
  //   });
  // };

  const getDashboardEnquirydata = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/student_attendance_chart",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      if (response.data.status === true) {
        console.log("4444444444", response.data.program);
        const existingData = response.data.program;
        const newObjects = [
          {
            "school_id": "1",
            "program_id": 1,
            "program_name": "Dc",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 2,
            "program_name": "Nursery",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 5,
            "program_name": "JKG",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 6,
            "program_name": "SKG",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 178,
            "program_name": "K1",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
          {
            "school_id": "1",
            "program_id": 7,
            "program_name": "SKG 2",
            "studentcount": 0,
            "studentAttendanceCount": 0
          },
        ];
        // const updatedData = [...existingData, ...newObjects];
        const updatedData = [...newObjects];
        setEnquiriesData(updatedData);
      }
    });
  };

  /* // sakthivel 04-05 JULY 2024 end // */


  const getSchooldDashboard = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/school_dashboard",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      if (response.data.status === true) {
        setSchoolDashboard(response.data);
      }
    });
  };

  useEffect(() => { }, [schoolDashboard]);

  const getDashboardLeaveApplied = () => {
    axios({
      method: "post",
      url: baseUrl + "/api/dashboard_leave_applied",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      if (response.data.status === true) {
        setLeaveApplied(response.data.data);
      }
    });
  };

  // --------------------14-12  Bharathi------------------

  const leaveApprove = async (item) => {

    console.log("leaveApprove_________", item);
    
    const apiEndpoint = item.student_id 
      ? baseUrl + "/api/leave_request_actions" 
      : baseUrl + "/api/staff_leave_request_actions";
  
    const postData = {
      id: item.id,
      updated_by: localStorage.getItem('loginUserId'),
      leave_status: 4,
      status_updated_by: localStorage.getItem('loginUserId'),
    };  

    if (!item.student_id) {
      postData.staff_id = item.staff_id;
    }

    console.log(apiEndpoint,'apiEndpoint');
    console.log(postData,'postData');
  
    try {
      const response = await axios({
        method: "post",
        url: apiEndpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('access_token'),
        },
        data: postData,
      });
  
      if (response.data.status === true) {
        handleLeaveApplyedClose();
        setRefreshNotification(true);
        setSnackbarMessage(response.data.message);
        setMessageType('success');
        handleSnackbarOpen();
        getSchooldDashboard();
        getDashboardLeaveApplied();
      } else {
        setSnackbarMessage(response.data.message);
        setMessageType('error');
        handleSnackbarOpen();
      }
    } catch (error) {
      console.error("Error in leaveApprove:", error);
      setSnackbarMessage("Something went wrong. Please try again.");
      setMessageType('error');
      handleSnackbarOpen();
    }
  };
  

  // const leaveApprove = async (item) => {
  //   // const isComfirm = window.confirm(
  //   //   "Are you sure you want to approve this leave?"
  //   // );
  //   // if (isComfirm) {
  //     console.log("leaveApprove_________", item);
  //     axios({
  //       method: "post",
  //       url: baseUrl + "/api/staff_leave_request_actions",
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         "Authorization": localStorage.getItem('access_token')
  //       },
  //       data: {
  //         id: item.id,
  //         updated_by: localStorage.getItem('loginUserId'),
  //         leave_status: 4,
  //         staff_id: item.staff_id,
  //         status_updated_by: localStorage.getItem('loginUserId'),
  //       },
  //     }).then((response) => {
  //       if (response.data.status === true) {
  //         handleLeaveApplyedClose();
  //         setRefreshNotification(true);
  //         setSnackbarMessage(response.data.message);
  //         setMessageType('success');
  //         handleSnackbarOpen();
  //         getSchooldDashboard();
  //         getDashboardLeaveApplied();
  //       } else {
  //         setSnackbarMessage(response.data.message);
  //         setMessageType('error');
  //         handleSnackbarOpen();
  //       }
  //     });
  //   // }
  // };
  
  // --------------------14-12  Bharathi------------------
  const getFormatedDate = (date, type) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return "N/A";
    }

    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isToday = dateObj.toDateString() === today.toDateString();
    const isTomorrow = dateObj.toDateString() === tomorrow.toDateString();
    const isYesterday = dateObj.toDateString() === yesterday.toDateString();

    if (type == "program") {
      return `${month} ${day}, ${year}`;
    } else if (type == "holiday") {
      return `${day} ${month} ${year}`;
    } else if (type == "current") {
      return `${month} ${day}, ${year}`;
    } else if (type == "leave") {
      return `${month} ${day} ${year}`;
    } else if (type == "birthday") {
      if (isToday) {
        return "Today";
      } else if (isTomorrow) {
        return "Tomorrow";
      } else if (isYesterday) {
        return "Yesterday";
      } else {
        const month = monthNames[dateObj.getMonth()];
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();
        return `${day} ${month} ${year}`;
      }
    }
  };

  const studentActivityTimeformat = (start, end) => {
    if (!start && !end) {
      return "N/A";
    }
    const options = { hour: "numeric", minute: "numeric" };
    const startDate = new Date(start);
    const endDate = new Date(end);
    const formattedStartTime = startDate.toLocaleTimeString([], options);
    const formattedEndTime = endDate.toLocaleTimeString([], options);
    return `${formattedStartTime} – ${formattedEndTime}`;
  };

  const getTimeDifference = (apiTime) => {
    const utcDate = new Date(apiTime);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = toZonedTime(utcDate, timeZone);
    let timeDifference = formatDistanceToNow(localDate, { addSuffix: true });
    timeDifference = timeDifference.replace(/^about /, "");
    return timeDifference;
  };

  const backgroundClasses = [
    "sp-row3-bt1",
    "sp-row3-bt2",
    "sp-row3-bt3",
    "sp-row3-bt4",
  ];

  // --------------------------------------

  const navigate = useNavigate();
  const [programsData, setProgramsData] = useState({});
  const [programsLoading, setProgramsLoading] = useState(false);

  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem('schoolId')
      }
      const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      setProgramsData(data.data);
      setProgramsLoading(true);
    } catch (error) {
      console.log(error)
    }
  }

  // ------11-06----------------
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };
  useEffect(() => { }, [snackbarMessage, messageType]);

  // -------------------------19-06-------------------------

  const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
  const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState(null);
  const handleOpenNewProgramPopupVisible = (status) => {
    setNewProgramAddUpdateStatus(status);
  }

  const [teachersData, setTeachersData] = useState([]);

  const fetchTeachersData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/teacher_list",
      data: {
        school_id: localStorage.getItem("schoolId")
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTeachersData(response.data.data);
        console.log(response);
      }
    });
  }

  //------------20-09 Bharathi start-------- 
  useEffect(() => {
    if (localStorage.getItem("notificationType") == 2) {
      setAppliedLeaveStatus(true);
    }
    localStorage.removeItem("notificationType")
  }, [appliedLeaveStatus]);

  //------------20-09 Bharathi end-------- 
  // --------------27-09 Bharathi start---------

  const [leaveApproveData, setLeaveApproveData] = useState({});
  const [leaveApplyedVisible, setLeaveApplyedVisible] = useState(false);

  const handleLeaveApplyedOpen = (item) => {
    setLeaveApproveData(item);
    setLeaveApplyedVisible(true);
  }

  const handleLeaveApplyedClose = () => {
    setLeaveApproveData({});
    setLeaveApplyedVisible(false);
  }

  //---------------27-09 Bharathi end----------- 

  useEffect(() => {
    fetchProgramsData();
    fetchTeachersData();
    getSchooldDashboard();
    getDashboardLeaveApplied();
    getDashboardchartdata();
    getDashboardEnquirydata();
  }, []);

  return (
    <section >
            <Snackbar className={`snack_total ${messageType === 'success' ?"snackbar_success":"snackbar_failure"}`}
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                    <div>
                        {messageType === 'success' ?
                        <span class="icon-List_Present" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>:                    
                        <span class="icon-List_Absent" style={{fontSize:" 21px;"}}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                        </span>
                        }
                    </div>
                            {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                // ContentProps={{
                //     sx: {
                //         border: "1px solid",
                //         opacity: "1",
                //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                //         width: 'auto',
                //         minWidth:'auto'
                //     }
                // }}
                autoHideDuration={3000}
            />
      <div className="outer-container dash_page" ref={dashboardRef}>
        <div className="overlay" />
        <div id="left-panel" className="sp-left-panel">
          <CommonSidebarHeader />
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="name_tag_side">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li className="selected">
                    <span>
                    <i className="icon-Dashboard" />
                      <p>Dashboard</p>
                    </span>
                  </li>
                  <li onClick={() => { navigate('/activity'); }}>
                    <span>
                    <i className="icon-Activity" />
                      <p>Activity</p>
                    </span>
                  </li>
                  <li onClick={() => { navigate('/calendar'); }}>
                    <span>
                    <i className="icon-Timetable" />
                      <p>Calendar</p>
                    </span>
                  </li>
                  <li onClick={() => { navigate('/staff_list'); }}>
                    <span>
                    <i className="icon-Staff" />
                      <p>Staff</p>
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                </ul>
                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible("add")}>
                  Programs{" "}
                  <span id="add-programs" className="sp-plus">
                    +
                  </span>
                </h4>
                <ul>
                  {programsLoading && programsData && programsData.length > 0 ?
                    programsData.map((data) => {
                      return (
                        <li onClick={() => {
                          localStorage.setItem("programId", data.id);
                          localStorage.setItem("programName", data.program_name);
                          navigate('/student_list');
                        }}>
                            <span
                                  className="ft-400 truncate"
                                  data-tooltip-id={`program-tooltip-${data.id}`}
                                  data-tooltip-content={data.program_name}
                                  data-tooltip-place="bottom"
                                >
                                  <i className="icon-Program_1" />
                                  <p>
                                    {
                                      (data.program_name && data.program_name.length <= 25) ? data.program_name : `${data.program_name.slice(0, 25)}...`
                                    }
                                  </p>
                            </span>
                                {data.program_name.length > 25 && <Tooltip id={`program-tooltip-${data.id}`} />}
                        </li>
                      );
                    }) :
                    <div className="data-loader">
                      <Loader
                        type="spinner-cub"
                        bgColor="#4D6EE3"
                        color="#6B6F8C"
                        title="Loading..."
                        size={35}
                      />
                    </div>
                  }
                </ul>
              </div>
            </div>

          </div>
        </div>
        {/* my code */}
        <div className="sp-right-panel">
          <div className="sp-dashboard-container">
            <div className="dashboard-inr">
              <div className="dashboard-top flexc justifySp">
                <p className="ft-wt700">Dashboard</p>
                {/* <button className="ft-wt500 customize_but">Customise</button> */}
              </div>
              {/* 25.07.2024 - Added class for tablet responsive */}
              <div
                className="dashboard-cont flexc justifySp tab-responsive"
                style={{ alignItems: "flex-start" }}
              >
                <div className="dashboard-cont-left">
                  {/* 25.07.2024 - Added class for tablet responsive */}
                  <div className="dash-row1 dash-row flexc justifySp tab-responsive">
                    <div className="dash-row1-box dash-row1-box1">
                      <span className="ft-wt600 ft-13">Student Attendance</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700 att-count">
                          {schoolDashboard.student_attendance}/
                          {schoolDashboard.student_count}
                        </span>
                        {/* <span className="dash-row1-range" /> */}
                        <img
                          src={dashboardavator1}
                          width={25}
                          height={25}
                          alt="Student Attendance"
                        />
                      </div>
                    </div>
                    <div className="dash-row1-box dash-row1-box2">
                      <span className="ft-wt600 ft-13">Teacher Attendance</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700 att-count">
                          {schoolDashboard.staff_attendance}/
                          {schoolDashboard.staff_count}
                        </span>
                        <img
                          src={dashboardavator2}
                          width={25}
                          height={25}
                          alt="Teacher Attendance"
                        />
                      </div>
                    </div>
                    <div className={`dash-row1-box dash-row1-box3`}>
                      <span className="ft-wt600 ft-13">Leave Applied</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700 att-count">
                          {schoolDashboard.leave_applied}
                        </span>
                        <img
                          src={dashboardavator3}
                          width={25}
                          height={25}
                          alt="Leave Applied"
                        />
                      </div>
                    </div>
                    <div className="dash-row1-box dash-row1-box4">
                      <span className="ft-wt600 ft-13">Absents</span>
                      <div className="dash-box1-btm flexc justifySp">
                        <span className="ft-20 ft-wt700 att-count">
                          {schoolDashboard.absents}
                        </span>
                        <img
                          src={dashboardavator4}
                          width={25}
                          height={25}
                          alt="Leave Applied"
                        />
                      </div>
                    </div>
                  </div>
                  
                  {/* 25.07.2024 - Added class for tablet responsive */}
                  <div className="dash-row2 dash-row flexc justifySp tab-responsive flex-wrap">  {/* 08-10-24 class add flex-wrap*/}
                    <div className="dash-bar-chart1 dash-bar-chart sp-dashboard">
                      <p className="ft-wt600 ft-13 dash-titles" style={{ marginBottom: "5%" }}>
                        Student Attendance by Class
                      </p>
                      <canvas
                        id="studentAttendanceChart"
                        width="400"
                        height="200"
                      >
                      </canvas>
                    </div>
                    <div className="dash-bar-chart2 dash-bar-chart sp-dashboard">
                      <p className="ft-wt600 ft-13 dash-titles" style={{ marginBottom: "5%" }}>New Enquiries</p>
                      <canvas
                        id="newEnquiriesChart"
                        width="400"
                        height="200"
                      ></canvas>
                      {/* <div className="bar-chart-cont flexc ft-13">
                        <ul>
                          <li>50</li>
                          <li>30</li>
                          <li>20</li>
                          <li>10</li>
                        </ul>
                        <ul className="chart-colum-cont flexc">
                          <li>
                            <span className="chart-colm1" /> DC
                          </li>
                          <li>
                            <span className="chart-colm2" /> Nursury
                          </li>
                          <li>
                            <span className="chart-colm3" /> JKG
                          </li>
                          <li>
                            <span className="chart-colm4" /> SKG
                          </li>
                          <li>
                            <span className="chart-colm5" /> K1
                          </li>
                          <li>
                            <span className="chart-colm6" /> SKG2
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                  <div
                    className="dash-row3 dash-row flexc justifySp programe-card"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {schoolDashboard.program?.length > 0 ? (
                      schoolDashboard.program.map((item, index) => {
                        const backgroundClass =
                          backgroundClasses[index % backgroundClasses.length];
                        return (
                          <>
                            <div
                              key={index}
                              className="dash-row3-box sp-dashboard"
                            >
                              <span className={`ft-wt600 program-name ${backgroundClass}`}>
                                {item.program_name}
                              </span>
                              <p className="ft-wt600 ft-13 program-title text-cap">{item.title}</p>
                              <span className="flexc sp-row3-dt  ft-13">
                                <i className="icon-Timetable" />
                                <span className="program-time">{getFormatedDate(item.start_date, "program")}</span>
                              </span>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* 25.07.2024 - Added class for tablet responsive */}
                  <div className="dash-row4 dash-row flexc justifySp tab-responsive">
                    <div className="dash-row4-activity sp-dashboard activity-main-card">
                      <div className="activity-row1 flexc justifySp">
                        <p className="ft-wt600 ft-13 dash-titles">Recent Activity</p>
                        {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                      </div>
                      {schoolDashboard.activity?.length > 0 ?
                      <>
                       <span className="activity-dt">
                        {getFormatedDate(new Date(), "current")}
                      </span>
                      <div className="activity-row2">
                        { (
                          schoolDashboard.activity
                            .slice(0, 3)
                            .map((item, index) => {
                              const maxTitleCharacters = 35;
                              const maxContentCharacters = 40;
                              const title =
                                item.post_title.length > maxTitleCharacters
                                  ? item.post_title.substring(
                                    0,
                                    maxTitleCharacters
                                  ) + "..."
                                  : item.post_title;
                              const content =
                                item.post_content.length > maxContentCharacters
                                  ? item.post_content.substring(
                                    0,
                                    maxContentCharacters
                                  ) + "..."
                                  : item.post_content;
                              return (
                                <div
                                  key={index}
                                  className="sp-activities flexc"
                                >
                                  <span className="sp-activ-bt sp-activ-bt1 flexc">
                                    <span className="sp-lpdr icon-sp-present" />
                                  </span>
                                  <div className="sp-activ-cont ft-13 extra_per_div">
                                    <p>
                                      <span className="ft-wt600 activity-title text-cap">{title}</span>{" "}
                                      <span className="actiity-content" dangerouslySetInnerHTML={{ __html: content }}></span>
                                    </p>
                                    <span className="sp-activ-hr activity-time">
                                      {getTimeDifference(item.created_on)}
                                    </span>
                                  </div>
                                </div>
                              );
                            })
                        ) 
                        // : (
                        //   <Nodata
                        //     contentType={"recentActivity"}
                        //   /> 
                          // <div
                          //   className="ft-13 grid place-items-center"
                          //   style={{ paddingTop: "50px" }}
                          // >
                          //   <p>No data found</p>
                          // </div>
                        // )
                      }
                      </div>
                      </>:<Nodata
                            contentType={"recentActivity"}
                          /> 
                     }

                    </div>
                    <div className="dash-row4-activity sp-dashboard activity-main-card">
                      <div className="activity-row1 flexc justifySp">
                        <p className="ft-wt600 ft-13 dash-titles">Students Activity time</p>
                        {/* <button className="ft-wt600 font_cus view_cus">
                          View all
                        </button> */}
                      </div>
                      <div className="sp-activity-row2 flexc justifySp activity-card">
                        <div className="activity-colum flexc justifySp">
                          <img src={run_green} style={{ width: "31%" }} alt="" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.checkinfirst,
                                schoolDashboard?.checkinlast
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Students Check in</p>
                          </div>
                        </div>
                        <div className="activity-colum flexc  dis_fe_st_pos">
                          {/* <span className="sp-activity-img" /> */}
                          <img src={lunch_timer_icon} style={{ width: "31%" }} alt="" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.foodfirsttime,
                                schoolDashboard?.foodlasttime
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Lunch Time</p>
                          </div>
                        </div>
                        <div className="activity-colum flexc justifySp">
                          {/* <span className="sp-activity-img" /> */}
                          <img src={run_red} style={{ width: "31%" }} alt="" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.checkoutfirst,
                                schoolDashboard?.checkoutlast
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Students Check Out</p>
                          </div>
                        </div>
                        <div className="activity-colum flexc  dis_fe_st_pos">
                          {/* <span className="sp-activity-img" /> */}
                          <img src={sleep_timer_icon} style={{ width: "31%" }} alt="" />
                          <div className="activity-colum-cont">
                            <span>
                              {studentActivityTimeformat(
                                schoolDashboard?.sleepfirsttime,
                                schoolDashboard?.sleeplasttime
                              )}
                            </span>
                            <p className="ft-wt600 ft-13">Sleep time</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-cont-right">
                  <div className="dash-right-row1 sp-dashboard">
                    <div className="activity-row1 flexc justifySp">
                      {/* 29-8-2024 */}
                      <p className="ft-wt600 ft-14 dash-titles">
                        Upcoming Birthday
                      </p>
                      {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                    </div>
                   {schoolDashboard.staff_dob?.length > 0 && <div className="dash-right-activ2 flexc justifySp">
                      <p className="ft-wt600 ft-13 sub-title">Name</p>
                      <p className="ft-wt600 ft-13 sub-title">Date</p>
                    </div>}
                    {/* 29-8-2024 */}
                    <div className="upcoming">
                      <div className="dash-right-activ3" style={{ height: '155px' }}>
                        {schoolDashboard.staff_dob?.length > 0 ? (
                          schoolDashboard.staff_dob
                            .map((item, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="dash-activ3-row flexc justifySp"
                                  >
                                    <div className="activ3-row1-left flexc">
                                      {(item.profile_image !== null && item.profile_image !== "")? (
                                        <>
                                          <img
                                            src={                                        
                                               baseUrl + "/api/" + item.profile_image
                                            }
                                            width={29}
                                            height={29}
                                            style={{ borderRadius: "8px" }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {/* <DashboardLogoComponent name={item.first_name} bgcolor={item.staff_color??item.std_color}/> */}
                                          <span className="text-xsmall acti_post_av  mr-rt10" style={{ backgroundColor: item.staff_color ?? item.std_color }}>{item.first_name.slice(0, 1).toUpperCase()}</span>
                                        </>
                                      )}
                                      {/* 11-09-2024 Start*/}
                                      <p className="ft-wt600 ft-13 text-cap">
                                        <span className="hol-title m-0">{item.first_name} {item.last_name}</span>
                                        <span className="ft-wt400 ft-12">{item.role_name ?? item.program_name}</span>
                                      </p>
                                      {/* 11-09-2024 end*/}
                                    </div>
                                    <span className="ft-wt400 ft-13 upcoming_dte_style">
                                      {getFormatedDate(item.dob_this_year, "birthday")}
                                    </span>
                                  </div>
                                </>
                              );
                            })
                        ) : (       
                          <Nodata
                           contentType={"upcomingBirthday"}
                          />                
                          // <div
                          //   className="ft-13 grid place-items-center"
                          //   style={{ padding: "5%" }}
                          // >
                          //   <p>No data found</p>
                          // </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="dash-right-row2 sp-dashboard">
                    <div className="activity-row1 flexc justifySp">
                      <p className="ft-wt600 ft-14 dash-titles">Upcoming Holidays</p>
                      {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                    </div>
                    {schoolDashboard.upcoming_holiday?.length > 0&&
                    <div className="dash-right-activ2 flexc justifySp">
                      <p className="ft-wt600 ft-13 sub-title">Holiday Name</p>
                      <p className="ft-wt600 ft-13 sub-title">Date</p>
                    </div>
                    }
                    {/* 29-8-2024 */}
                    <div className="upcoming">
                      <div className="dash-right-activ3" style={{ height: '180px' }}>
                        {schoolDashboard.upcoming_holiday?.length > 0 ? (
                          schoolDashboard.upcoming_holiday
                            .map((item, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="dash-activ3-row flexc justifySp"
                                  >
                                    <div className="activ3-row1-left flexc">
                                      <span className="holi-dt-ic flexc">
                                        <i className="icon-Timetable" />
                                      </span>
                                      {/* 11-09-2024 Start*/}
                                      <p className="ft-wt600 ft-13 text-cap" style={{ marginTop: "-5px" }}>
                                        <span className="hol-title">{item.title}</span>
                                        <span className="ft-wt400 ft-12">
                                          {item.program_name ?? "All Program"}
                                        </span>
                                      </p>
                                      {/* 11-09-2024 end*/}
                                    </div>
                                    <span className="ft-wt400 ft-12 upcoming_dte_style">
                                      {getFormatedDate(item.start_date, "holiday")}
                                    </span>
                                  </div>
                                </>
                              );
                            })
                        ) : (
                          <Nodata
                            contentType={"upcomingHolidays"}
                          /> 
                          // <div
                          //   className="ft-13 grid place-items-center"
                          //   style={{ padding: "5%" }}
                          // >
                          //   <p>No data found</p>
                          // </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div ref={targetElementRef} className={`dash-right-row3 sp-dashboard ${appliedLeaveStatus == true ? "leave-widget-highlight" : ""}`}>  {/* 12-09-2024 single line */}
                    <div className="activity-row1 flexc justifySp">
                      <p className="ft-wt600 ft-14 dash-titles">Leave Applied</p>
                      {/* <button className="ft-wt600 font_cus view_cus">View all</button> */}
                    </div>
                    {leaveApplied?.length > 0&&
                    <div className="dash-right-activ2 flexc justifySp">
                      <p className="ft-wt600 ft-13 sub-title">Name</p>
                      {/* <p className="ft-wt600 ft-13">Date</p> */}
                    </div>
                    }
                    {/* 29-8-2024 */}
                    <div className="upcoming">
                      <div className="dash-right-activ3" style={{ height: '155px' }}>
                        {leaveApplied?.length > 0 ? (
                          leaveApplied.map((item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="dash-activ3-row flexc justifySp"
                                >
                                  <div className="activ3-row1-left flexc leave_app_img_style">
                                    {(item.profile_image !== null && item.profile_image !== "") ? (
                                      <>
                                        <img
                                          src={
                                            baseUrl + "/api/" + item.profile_image                          
                                          }
                                          // src={avatar3}
                                          width={29}
                                          height={29}
                                          style={{ borderRadius: "8px" }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                       <span className="text-xsmall acti_post_av  mr-rt10" style={{ backgroundColor: item.staff_color ?? item.std_color }}>{item.first_name.slice(0, 1).toUpperCase()}</span>
                                        {/* <DashboardLogoComponent name={item.first_name} bgcolor={item.staff_color} /> */}
                                        {/* <span className="text-xsmall top_name_single mr-rt10">{item.first_name.slice(0, 1).toUpperCase()}</span> */}
                                      </>
                                    )}
                                    <p className="ft-wt600 ft-13 text-cap">
                                      {item.first_name} {item.last_name}
                                      <span className="ft-wt400 ft-12 ">
                                        {getFormatedDate(
                                          item.start_date,
                                          "leave"
                                        )}{" "}
                                        -{" "}
                                        {getFormatedDate(item.end_date, "leave")}
                                      </span>
                                    </p>
                                  </div>
                                  <button
                                    class="ft-12"
                                    onClick={() => handleLeaveApplyedOpen(item)}
                                  >
                                    Approve
                                  </button>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <Nodata
                            contentType={"leaveApplied"}
                          /> 
                          // <div
                          //   className="ft-13 grid place-items-center"
                          //   style={{ padding: "5%" }}
                          // >
                          //   <p>No data found</p>
                          // </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {/ leave applyed Modal /} */}
        {
          leaveApplyedVisible && (
            <div
              id="recycle-popup"
              tabIndex={-1}
              className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
            >
              <div className="relative w-full max-w-xs max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">  
                 <div className="p-4 md:p-5 text-center">
                  <div className="info_round_btn  rounded inline-flex alignC justify-center">
                  <span class="icon-Info_Modal"></span>
                  </div>
                  <h3 className="mb-5 font-normal text-sm">
                    Are you sure you want to approve this leave?
                  </h3>
                  <div className="att-popup">
                    <button
                      onClick={handleLeaveApplyedClose}
                      type="button"
                      className="att-cancel can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                    >
                      Cancel
                    </button>
                    <button
                      // onClick={()=>{setCheckProgramChangeStatus(true); updateUser();}}
                      onClick={() => leaveApprove(leaveApproveData)}
                      type="button"
                      className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                    >
                      Yes, Proceed
                    </button>
                  </div>
                </div>
                </div>
              </div>
            </div>
          )
        }
        {/* Create New Program */}
        <ProgramAddComponent
          newProgramPopupVisible={newProgramPopupVisible}
          setNewProgramPopupVisible={setNewProgramPopupVisible}
          teachersData={teachersData}
          setProgramsData={setProgramsData}
          newProgramAddUpdateStatus={newProgramAddUpdateStatus}
          setNewProgramAddUpdateStatus={setNewProgramAddUpdateStatus}
        />
      </div>
      {/* 12-09-2024 start */}
      <CommonSidebarFooter
        setAppliedLeaveStatus={handleAppliedLeaveStatus}
        refreshNotification={refreshNotification}
        setRefreshNotification={setRefreshNotification}
      />
      {/* 12-09-2024 end */}
    </section>
  );
}
