import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import '../components/js/common.js';
import { baseUrl } from "../common.jsx";
import gift from "../components/images/gift.svg";
import no1 from "../components/images/no1.svg";
import no2 from "../components/images/no2.svg";
import no3 from "../components/images/no3.svg";
import no4 from "../components/images/no4.svg";
import no5 from "../components/images/no5.svg";
import no6 from "../components/images/no6.svg";
import no8 from "../components/images/no8.svg";
import no9 from "../components/images/no9.svg";
import no10 from "../components/images/no10.svg";
import no11 from "../components/images/no11.svg";
import no12 from "../components/images/no12.svg";
import no23 from "../components/images/no23.svg";
import no24 from "../components/images/no24.svg";
import no25 from "../components/images/no25.svg";
import Setting from "../components/images/Setting.svg";
import Logout from "../components/images/Logout.svg";
import notification from "../components/images/notification.svg";
import { toZonedTime } from 'date-fns-tz';
import { Avatar, Dropdown } from "flowbite-react";

import { differenceInHours, differenceInMinutes, differenceInSeconds, differenceInDays, format } from 'date-fns';
{/*12-09-2024 start */ }
export default function CommonSidebarFooter({ refreshNotification, setRefreshNotification, setAppliedLeaveStatus,setHighlightPost}) {
    {/*12-09-2024 end */ }

    const navigate = useNavigate();
    const location = useLocation();

    // 15
    const [showNotification, setShowNotification] = useState(false);
    const handleNotificationClick = () => {
        setShowNotification(prevState => !prevState);
        setIsClicked(prevState => !prevState);
    };

    // 17-07-2024
    const [isClicked, setIsClicked] = useState(false);
    const notiTotRef = useRef(null);
    const showNotificationRef = useRef(null);
    const notiTotStyle = isClicked
        ? {
            backgroundColor: "#EFF2FF",
            borderColor: "var(--sp-hg-clr)",
            boxShadow: "rgba(190, 196, 224, 0.2) 0px 1px 3px 0px",
            border: "1px solid transparent",
            zIndex: -1,
            borderRadius: "6px",
            position: "relative",
            left: "-4px",
            width: " calc(100% + 10px)",
            padding: "12px 6px 12px 4px",
        }
        : {};

    // -----------25-07----------------

    const [notificationData, setNotificationData] = useState([]);
    const fetchNotificationDetails = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/notification_list",
            data: {
                school_id: localStorage.getItem("schoolId"),
                login_user_id: localStorage.getItem("loginUserId"),
                login_user_role: 4
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setNotificationData(response.data.data);
                console.log(response);
                console.log("getUnreadNotification: " + getUnreadNotification(response.data.data));
            }
        });
    }

    function convertNotificationDate(notifyDate) {
        const date = toZonedTime(new Date(notifyDate), 'UTC');
        const now = new Date();
        const daysDifference = differenceInDays(now, date);
        const hoursDifference = differenceInHours(now, date);
        const minutesDifference = differenceInMinutes(now, date);
        const secondsDifference = differenceInSeconds(now, date);

        if (daysDifference > 0) {
            return format(date, 'dd MMM');
        }
        if (hoursDifference > 0) {
            return `${hoursDifference} hr`;
        }
        if (minutesDifference > 0) {
            return `${minutesDifference} minutes ago`;
        }
        if (secondsDifference > 0) {
            return `${secondsDifference} seconds ago`;
        }
        return 'Just now';
    }

    const handleClickOutside = (event) => {
        if (showNotificationRef.current && !showNotificationRef.current.contains(event.target)) {
            setShowNotification(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // --------26-07---------
    const [unreadNotificationIds, setUnreadNotificationIds] = useState([]);
    function getUnreadNotification(notificationData) {
        const unreadNotifications = notificationData.filter(notification => notification.read_status === 1);
        const notificationIds = unreadNotifications.map(notification => notification.id);
        setUnreadNotificationIds(notificationIds);
    }

    // 12-09-2024 start
    const changeNotificationStatus = async (status, notificationId, data) => {

        if (data.notification_type == 2) {
            if (typeof setAppliedLeaveStatus === 'function') {
                setAppliedLeaveStatus(true);
            }
        } else {
            if (typeof setAppliedLeaveStatus === 'function') {
                setAppliedLeaveStatus(false);
            }
        }

        // if (data.notification_type===2) {
        //     // -----------20-09 Bharathi-------
        //     localStorage.setItem("notificationType", data.notification_type);
        //     navigate("/dashboard")
        //     // -----------20-09 Bharathi end-------     
        // } else if(data.notification_type===4) {
        //     const parsedData = JSON.parse(data.notification_info);      
        //     localStorage.setItem("postNotificationId", parsedData[0].id);
        //     localStorage.setItem("notificationType", data.notification_type);
        //     navigate("/activity")
        // }

        // 12-09-2024 end
        if(data.read_status===1||status === 2){
            axios({
                method: "post",
                url: baseUrl + "/api/notification_read_status",
                data: {
                    school_id: localStorage.getItem("schoolId"),
                    ids: status === 1 ? [notificationId] : unreadNotificationIds
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    const parsedData = JSON.parse(data.notification_info);  
                    fetchNotificationDetails();
                    switch (data.notification_type) {
                    case 1:
                        navigate("/calendar");
                        break;
                    case 2:             
                        localStorage.setItem("notificationType", data.notification_type);
                        navigate("/dashboard");
                        break;
                    case 3:
                        navigate("/student_attendance");
                        break;
                    case 4:         
                        localStorage.setItem("postNotificationId", parsedData[0].id);
                        localStorage.setItem("notificationType", data.notification_type);
                        if(location.pathname=="/activity"){
                            window.location.reload();
                        }
                        navigate("/activity");
                        break;
                    case 5:
                        navigate("/student_attendance");
                        break;
                    case 6:
                        navigate("/student_attendance");
                        break;
                    case 7:
                        navigate("/food");
                        break;
                    case 8:
                        navigate("/sleep");
                        break;
                    case 9:
                        navigate("/student_timetable");
                        break;
                    case 10:
                        localStorage.setItem("postNotificationId", parsedData[0].id);
                        localStorage.setItem("notificationType", data.notification_type);
                        if(location.pathname=="/activity"){
                            window.location.reload();
                        }
                        navigate("/activity");
                        break;
                    // case 11:

                    //     break;
                    // case 12:

                    //     break;
                    // case 13:

                    //     break;
                    // case 14:

                    //     break;
                    // default:
                
                    }
                }
            });
        }else{
            const parsedData = JSON.parse(data.notification_info);   
            switch (data.notification_type) {              
                case 1:
                    navigate("/calendar");
                    break;
                case 2:
                    localStorage.setItem("notificationType", data.notification_type);
                    navigate("/dashboard");
                    break;
                case 3:
                    navigate("/student_attendance");
                    break;
                case 4:                       
                    localStorage.setItem("postNotificationId", parsedData[0].id);
                    if(location.pathname=="/activity"){
                        window.location.reload();
                    }
                    localStorage.setItem("notificationType", data.notification_type);                  
                    navigate("/activity");
                    break;
                case 5:
                    navigate("/student_attendance");
                    break;
                case 6:
                    navigate("/student_attendance");
                    break;
                case 7:
                    navigate("/food");
                    break;
                case 8:
                    navigate("/sleep");
                    break;
                case 9:
                    navigate("/student_timetable");
                    break;
                case 10:
                    localStorage.setItem("postNotificationId", parsedData[0].id);
                    if(location.pathname=="/activity"){
                        window.location.reload();
                    }
                    localStorage.setItem("notificationType", data.notification_type);                  
                    navigate("/activity");
                    break;
                // case 11:
    
                //     break;
                // case 12:
    
                //     break;
                // case 13:
    
                //     break;
                // case 14:
    
                //     break;
                // default:
              
            }
        }

    }

    // const [refreshNotification, setRefreshNotification] = useState(false);

    // ---------------------20-09------------------
    const [adminData, setAdminData] = useState({});
    const fetchAdminData = async () => {
      try {
        const response = await axios.post(
          baseUrl + '/api/get_staff',
          {
            id: localStorage.getItem('loginUserId'),
            school_id: localStorage.getItem('schoolId'),
          },
          {
            headers: {
              Authorization: localStorage.getItem('access_token'),
            },
          }
        );
        console.log(response.data.data[0]);
        setAdminData(response.data.data[0] || {});
      } catch (error) { }
    };

    useEffect(() => {
        fetchNotificationDetails();
        fetchAdminData();
    }, []);

    useEffect(() => {
        if (refreshNotification) {
            fetchNotificationDetails();
            setRefreshNotification(false);
        }
    }, [refreshNotification]);

    return (
        <section>
            <div className="sp-bottom sp-nav" >
                <ul>
                    <li onClick={handleNotificationClick}>
                        <span style={{ display: "block" }}>
                            <div className="noti_tot">
                                <div className="noti_sdi">
                                <i className="icon-Notification"/>    
                                    {/* <img src={gift} alt="" style={{ height: "18px", width: "18px" }} /> */}
                                    <p>Notifications</p>
                                </div>
                                {
                                    (notificationData.filter(item => item.read_status === 1).length) > 0 &&
                                    <div className="noti_left" id="notify_popup" >
                                        <p>{notificationData.filter(item => item.read_status === 1).length}</p>
                                    </div>
                                }
                            </div>
                        </span>
                    </li>
                    {/* <li> 
                        <span style={{ display: "block" }}>                          
                            <div className="noti_tot">
                                <div className="noti_sdi">
                                    <img src={upar} alt="" style={{ height: "18px", width: "18px" }} />
                                    <p>Upgrade Pro</p>
                                </div> 
                                <img src={ques} alt="" style={{ height: "18px", width: "18px" }} />
                            </div>
                        </span>
                    </li> */}
                    <li className="my_pro_div">
                        <Dropdown
                            label={
                                <div className="log_out_set">
                                    {adminData && (adminData['profile_image']!==null&&adminData['profile_image']!=="")?<Avatar img={baseUrl + '/api/' + adminData['profile_image']}/>
                                    :adminData.first_name && (
                                      <span className="text-xsmall acti_post_av mr-rt10"
                                         style={{backgroundColor: adminData.staff_color}} >        
                                         {adminData.first_name.slice(0, 1).toUpperCase()}
                                      </span>
                                    // <Avatar placeholderInitials={adminData.first_name.slice(0, 1).toUpperCase()} />
                                    )} 
                                <div><p>My Profile</p></div>
                                </div>} inline>
                            <Dropdown.Item onClick={() => navigate("/setting")}>
                            <span class="icon-Settings"></span>
                                Settings
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => navigate("/signout")}>
                            <span class="icon-Logout"></span>
                                Logout
                            </Dropdown.Item>
                        </Dropdown>
                    </li>
                    {/* <li onClick={() => navigate("/signout")}>
                        <span>
                            <i className="icon-Invites-Outline" />
                            <p>Log Out</p>
                        </span>
                    </li> */}
                </ul>
            </div>
            {
                showNotification && (
                    <div id="notify_popup" ref={showNotificationRef} className="bg-white media_style_pop notifyStyle" >
                        <div className="notification_header">
                            <div className="noti_flex">
                                <div className="noti_dis_flex1">
                                    <h3>Notifications</h3>
                                </div>
                                {
                                    notificationData &&
                                    notificationData.filter(item => item.read_status === 1).length > 0 && (
                                        <div className="noti_dis_flex" onClick={() => changeNotificationStatus(2, "", '')}> {/*12-09-2024 single line */}
                                            <h4>Mark all as read</h4>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="notification_body">
                            {notificationData.map((data, index) => {
                                let notificationImg;
                                switch (data.notification_type) {
                                    case 1:
                                        notificationImg = no1;
                                        break;
                                    case 2:
                                        notificationImg = no2;
                                        break;
                                    case 3:
                                        notificationImg = no3;
                                        break;
                                    case 4:
                                        notificationImg = no10;
                                        break;
                                    case 5:
                                        notificationImg = no8;
                                        break;
                                    case 6:
                                        notificationImg = no9;
                                        break;
                                    case 7:
                                        notificationImg = no11;
                                        break;
                                    case 8:
                                        notificationImg = no12;
                                        break;
                                    case 9:
                                        notificationImg = no5;
                                        break;
                                    case 10:
                                        notificationImg = no25;
                                        break;
                                    case 11:
                                        notificationImg = no23;
                                        break;
                                    case 12:
                                        notificationImg = no24;
                                        break;
                                    case 13:
                                        notificationImg = no4;
                                        break;
                                    case 14:
                                        notificationImg = no6;
                                        break;
                                    default:
                                        notificationImg = null;
                                }
                                return (
                                    <div className={`full_body_notifi ${data.read_status === 1 && "active_cls"}`} key={index} onClick={() => changeNotificationStatus(1, data.id, data)}
                                     style={{cursor:"pointer"}}
                                    >   {/*12-09-2024 single line */}
                                        <div className="full_noti_body">
                                            <div className="colnot2">
                                                {notificationImg && (
                                                    <img src={notificationImg} alt="Notification" style={{ width: "36px", height: "36px" }} />
                                                )}
                                            </div>
                                            <div className="colnot7">
                                                <h3>{data.notification_title}</h3>
                                                <p><span>{data.notification_description}</span></p>
                                            </div>
                                            {
                                                data.read_status === 1 &&
                                                <div className="colnot1 noti_dot">
                                                    <i className="fa fa-circle"></i>
                                                </div>
                                            }
                                        </div>
                                        <div className="day_notify">
                                            <p>{convertNotificationDate(data.created_on)}</p>
                                        </div>
                                    </div>
                                );
                            })}
                         {
                            notificationData.length===0&&
                            <div className="noti_no_process">
                                <img src={notification} />
                                <h4>No Notifications</h4>
                                <p>We’ll let you know when there will be something to <br />update you.</p>
                            </div>
                            }
                        </div>
                    </div>
                )
            }
        </section >
    );
}