import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../common/components/js/common.js';
import { baseUrl } from '../../common/common';
import '../../common/components/css/style.css';
import { Dropdown } from 'flowbite-react';
import { HiCheck } from 'react-icons/hi';
import '../../index.css';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import doc from './images/doc.svg';
import imgSend from './images/send.png';
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import '../../common/components/css/style1.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import close from './images/close.svg';
import imgClose from './images/img-close.svg';
import imgPdf from './images/pdf-img.svg';
import adddoc from './images/adddoc.svg';
import Image from './images/Image.svg';
import { format, parse } from 'date-fns';
import { WindmillSpinner } from 'react-spinner-overlay';
import de from './images/de.svg';
import CommonSidebarFooter from '../../common/layouts/CommonSidebarFooter.jsx';
import draftToHtml from 'draftjs-to-html';
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";
import { ActivityLogoComponent } from "./Common.jsx";
import ProgramAddComponent from "../programs/AddProgram.jsx";
import nodatact from "../nodata/images/nodatact.svg";
import { Tooltip } from 'react-tooltip';

export default function Activity() {
  const location = useLocation();
  const navigate = useNavigate();

  const [activityData, setActivityData] = useState([]);
  const [activitysLoading, setActivityLoading] = useState(false);
  const fetchActivity = () => {
    //setActivityLoading(false);
    try {
      axios({
        method: 'post',
        url: baseUrl + '/api/get_post',
        data: {
          school_id: localStorage.getItem('schoolId'),
          // school_id:1,
          admin: 1,
          limit_value: 200,
          limit_offset: 0,
          // user_id:1
          user_id: localStorage.getItem('loginUserId'),
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('access_token'),
        },
      }).then(response => {
        console.log(response);
        if (response['data']['status'] === true) {
          setActivityData(response.data.data);
          setActivityLoading(true);
          if (localStorage.getItem("notificationType") == 4 || localStorage.getItem("notificationType") == 10) {
            setHighlightPost(true);
            setHighlightPostId(localStorage.getItem("postNotificationId"))
          }
          localStorage.removeItem("notificationType");
          localStorage.removeItem("postNotificationId");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [programsData, setProgramsData] = useState({});
  const [programsLoading, setProgramsLoading] = useState(false);

  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem('schoolId'),
        // school_id:24
      };
      const { data } = await axios.post(
        baseUrl + '/api/get_programs',
        postData,
        {
          headers: {
            Authorization: localStorage.getItem('access_token'),
          },
        }
      );
      setProgramsData(data.data);
      setProgramsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };


  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState([]);
  //------------------- image upload -------------
  // const handleImageChange = (event) => {
  //     const files = event.target.files;
  //     const file = event.target.files[0];
  //     setSelectedImage(file);
  // };
  // ----------------------------------------------
  //------------------- document upload -----------
  // const handleDocumentChange = (event) => {
  //     const files = event.target.files;
  //     const file = event.target.files[0];
  //     setSelectedDocument(file);
  // };
  // ----------------------------------------------

  const [postTitle, setPostTitle] = useState('');
  const handleImageChange = event => {
    const selectedFiles = Array.from(event.target.files);
    setSelectedImage(prevImages => [...prevImages, ...selectedFiles]);
  };

  const handleRemoveImage = index => {
    const newSelectedImages = [...selectedImage];
    newSelectedImages.splice(index, 1);
    setSelectedImage(newSelectedImages);
  };

  const handleDocumentChange = event => {
    const selectedFiles = Array.from(event.target.files);
    setSelectedDocument(prevImages => [...prevImages, ...selectedFiles]);
  };

  const handleRemoveDocument = index => {
    const newSelectedDocuments = [...selectedDocument];
    newSelectedDocuments.splice(index, 1);
    setSelectedDocument(newSelectedDocuments);
  };


  const downloadDocument = async (documentLink, documentName) => {
    const response = await fetch(baseUrl + '/api/' + documentLink);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = documentName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };



  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const slideRef = useRef(null);

  const nextSlide = () => {
    slideRef.current.goNext();
  };

  const previousSlide = () => {
    slideRef.current.goBack();
  };


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sliderDefaultIndex, setSliderDefaultIndex] = useState(1);
  const [activity, setActivity] = useState({});
  const openModal = data => {
    console.log(data);
    setActivity(data);
    setActivityId(data.id);
    console.log(activity);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    $('.modal-lightbox').removeClass('visible');
  };


  const removeStylesFromHTML = htmlString => {
    // Parse the HTML string into a DOM object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Remove all style attributes
    const elementsWithStyle = doc.querySelectorAll('[style]');
    elementsWithStyle.forEach(element => element.removeAttribute('style'));

    // Remove all <style> tags
    const styleTags = doc.querySelectorAll('style');
    styleTags.forEach(tag => tag.remove());

    // Convert the DOM object back into a string
    return doc.body.innerHTML;
  };

  const onEditorStateChange = newState => {
    setEditorState(newState);
    const contentState = newState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    // const html = convertRawContentToHTML(rawContentState);
    const html = draftToHtml(rawContentState);
    console.log(removeStylesFromHTML(html));
    setPostContent(removeStylesFromHTML(html));
  };

  const [postContent, setPostContent] = useState('');

  const [activityId, setActivityId] = useState('');
  const [addPostStatus, setAddPostStatus] = useState(false);
  const addPost = () => {
    setAddPostStatus(true);
    try {
      // if (selectedImage.length === 0 && selectedDocument.length === 0 && postTitle === '') {
      if (postTitle === '') {
        setSnackbarMessage('Please enter the activity title');
        setMessageType('error');
        handleSnackbarOpen();
      } else {
        // else if (selectedDocument.length === 0) {
        //     setFileError("Please Choose Document")
        //     setOpen(true);
        // }
        // else if (postContent === '') {
        //     setFileError("Please Enter Post Content")
        //     setOpen(true);
        // }
        // else if (template === '') {
        //     setFileError("Please Select Teplate")
        //     setOpen(true);
        // }
        const baseData = {
          staff_id: localStorage.getItem('loginUserId'),
          post_image: selectedImage,
          attachment_file: selectedDocument,
          template_id: template,
          post_title: postTitle,
          post_content: postContent,
          reminder: '',
          admin: 1,
          school_id: localStorage.getItem('schoolId'),
          programs_id: programId,
          status: 1,
        };

        const postData = activityId === ''
          ? { ...baseData, created_by: localStorage.getItem('loginUserId') }
          : {
            ...baseData,
            id: activityId,
            updated_by: localStorage.getItem('loginUserId'),
          };

        console.log(postData);
        axios({
          method: 'post',
          url: baseUrl + '/api/post_add_edit',
          data: postData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem('access_token'),
          },
        }).then(response => {
          console.log(response);
          if (response.data.status === true) {
            handleModalClose();
            // setSelectedImage([]);
            // setSelectedDocument([]);
            // setTemplate('');
            // setPostContent('');
            // setPostTitle('');
            // setEditorState(EditorState.createEmpty());
            // $('.announce-post-inr').slideUp();
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
            fetchActivity();
            if (activityId === '') {
              setRefreshNotification(true);
            }
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      }
    } catch (error) {
    } finally {
      setAddPostStatus(false);
    }
  };

  const [programId, setProgramId] = useState(0);
  const [programs, setPrograms] = useState([]);
  const selectedProgram = programs.find(program => program.id === programId);
  const fetchPrograms = async () => {
    try {
      const response = await axios.post(
        baseUrl + '/api/get_programs',
        { school_id: localStorage.getItem('schoolId') },
        {
          headers: {
            Authorization: localStorage.getItem('access_token'),
          },
        }
      );
      setPrograms(response.data.data);
    } catch (error) { }
  };

  const [comment, setComment] = useState('');

  const [commentAddStatus, setCommentAddStatus] = useState(false);
  const handleSendComment = postId => {
    setCommentAddStatus(true);
    try {
      axios({
        method: 'post',
        url: baseUrl + '/api/comments_add_edit',
        data: {
          school_id: localStorage.getItem('schoolId'),
          post_id: postId,
          user_id: localStorage.getItem('loginUserId'),
          user_name: localStorage.getItem('loginUserName'),
          role_id: localStorage.getItem('roleId'),
          comment: comment,
          status: 1,
          created_by: localStorage.getItem('loginUserId'),
          updated_by: localStorage.getItem('loginUserId'),
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('access_token'),
        },
      }).then(response => {
        console.log(response);
        if (response.data.status === true) {
          setComment('');
          // setIsModalVisible(false);
          // $('.modal-lightbox').removeClass('visible');
          if (isModalVisible) {
            fetchActivityDetails();
          }
          setSnackbarMessage(response.data.message);
          setMessageType('success');
          handleSnackbarOpen();
          fetchActivity();
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCommentAddStatus(false);
    }
  };

  const handleSendLike = (postId, status) => {
    try {
      axios({
        method: 'post',
        url: baseUrl +
          '/api/' +
          (status === 'add' ? 'likes_add' : 'likes_delete'),
        data: status === 'add'
          ? {
            school_id: localStorage.getItem('schoolId'),
            post_id: postId,
            user_id: localStorage.getItem('loginUserId'),
            role_id: localStorage.getItem('roleId'),
            status: 1,
            created_by: localStorage.getItem('loginUserId'),
            updated_by: localStorage.getItem('loginUserId'),
          }
          : {
            post_id: postId,
            user_id: localStorage.getItem('loginUserId'),
            role_id: localStorage.getItem('roleId'),
          },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('access_token'),
        },
      })
        .then(response => {
          console.log(response);
          if (response.data.status === true) {
            // setComment('');
            // setIsModalVisible(false);
            // $('.modal-lightbox').removeClass('visible');
            if (isModalVisible) {
              fetchActivityDetails();
            }
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
            fetchActivity();
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const extractTextFromHTML = html => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const wordCount = text => {
    return text.split(/\s+/).filter(word => word.length > 0).length;
  };

  // Function to strip HTML tags for truncation
  // const stripHTMLTags = (html) => {
  //   const div = document.createElement('div');
  //   div.innerHTML = html;
  //   return div.innerText;
  // };

  // ------11-06----------------
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [messageType, setMessageType] = useState('');

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };

  useEffect(() => { }, [snackbarMessage, messageType]);

  // ----------------14-06-----------------------
  const [template, setTemplate] = useState('');
  const [templateData, setTemplateData] = useState([]);
  // const selectedTemplate = templateData.find(data => data.id === template);
  const fetchTemplateData = async () => {
    axios({
      method: 'post',
      url: baseUrl + '/api/get_template',
      data: {
        school_id: localStorage.getItem('schoolId'),
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('access_token'),
      },
    }).then(response => {
      console.log(response);
      if (response.data.status === true) {
        setTemplateData(response.data.data);
        console.log(response);
      }
    });
  };

  // const [selectedTemplate, setSelectedTemplate] = useState(null);
  const selectedTemplate = templateData.find(data => data.id === template);
  const [templateChangeData, setTemplateChangeData] = useState({});
  const changeTemplateData = template => {
    setTemplateChangeData(template);
    if (changeMessageVisible) {
      try {
        setTemplate(template.id);
        // setSelectedTemplate(template);
        setPostContent(template.template_content);
        // if (template && typeof template.template_content === 'string') {
        //   const contentState = ContentState.createFromText(
        //     template.template_content
        //   );
        //   const newEditorState = EditorState.createWithContent(contentState);
        //   // setEditorState(newEditorState);
        //   onEditorStateChange(newEditorState);
        // } else {
        //   throw new Error('Invalid content format');
        // }
      } catch (error) {
        console.error('Error changing template data:', error);
        setEditorState(EditorState.createEmpty());
      }
    } else {
      setChangeMessageVisible(true);
    }
  };

  const changeProgramsData = programId => {
    // handleModalOpen();
    setProgramId(programId);
    // $('.announce-post-inr').slideDown();
  };

  const contentRef = React.createRef();

  const contentStyle = {
    // maxHeight: showFullContent
    //   ? 'none'
    //   : contentRef.current &&
    //       `calc(5 * ${parseFloat (window.getComputedStyle (contentRef.current).lineHeight)})`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
  };

  // -------------------------19-06-------------------------

  const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
  const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState(null);
  const handleOpenNewProgramPopupVisible = (status) => {
    setNewProgramAddUpdateStatus(status);
  }

  const [teachersData, setTeachersData] = useState([]);

  const fetchTeachersData = async () => {
    axios({
      method: 'post',
      url: baseUrl + '/api/teacher_list',
      data: {
        school_id: localStorage.getItem('schoolId'),
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('access_token'),
      },
    }).then(response => {
      console.log(response);
      if (response.data.status === true) {
        setTeachersData(response.data.data);
        console.log(response);
      }
    });
  };

  // --------------------------02-07---------------------------
  const style = {
    position: 'absolute',
    top: '50%',
    left: '57%',
    transform: 'translate(-50%, -50%)',
    width: 720,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    //  p: 4,
  };
  const [openActivityModal, setActivityOpenModal] = useState(false);
  const handleModalOpen = activityId => {
    if (activityId === '') {
      setActivityId('');
      setActivityUpadteImage([]);
      setActivityUpadteDocument([]);
      setActivityOpenModal(true);
    } else {
      setActivityId(activityId);
      fetchEditProgramDetails(activityId);
    }
  };

  const [activityUpadteImage, setActivityUpadteImage] = useState([]);
  const handleRemoveActivityUpadteImage = async (index, imgPath) => {
    try {
      axios({
        method: 'post',
        url: baseUrl + '/api/removefile',
        data: {
          id: activityId,
          file_path: imgPath,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('access_token'),
        },
      }).then(response => {
        console.log(response);
        if (response.data.status === true) {
          const newSelectedImages = [...activityUpadteImage];
          newSelectedImages.splice(index, 1);
          setActivityUpadteImage(newSelectedImages);
          fetchActivity();
        } else {
          setSnackbarMessage('Something Went Wrong');
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    } catch (error) {
      console.error(
        'An error occurred while fetching program details:',
        error
      );
    }
  };

  const [activityUpadteDocument, setActivityUpadteDocument] = useState([]);

  const handleRemoveActivityUpadteDocument = (index, filePath, fileName) => {
    try {
      axios({
        method: 'post',
        url: baseUrl + '/api/removedoc',
        data: {
          id: activityId,
          attachment_name: fileName,
          attachment_file: filePath,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('access_token'),
        },
      }).then(response => {
        console.log(response);
        if (response.data.status === true) {
          const newSelectedDocuments = [...activityUpadteDocument];
          newSelectedDocuments.splice(index, 1);
          setActivityUpadteDocument(newSelectedDocuments);
          fetchActivity();
        } else {
          setSnackbarMessage('Something Went Wrong');
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    } catch (error) {
      console.error(
        'An error occurred while fetching program details:',
        error
      );
    }
  };

  const fetchEditProgramDetails = async activityId => {
    try {
      axios({
        method: 'post',
        url: baseUrl + '/api/post_details',
        data: {
          school_id: localStorage.getItem('schoolId'),
          user_id: localStorage.getItem('loginUserId'),
          id: activityId,
          admin: 1,
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.getItem('access_token'),
        },
      }).then(response => {
        console.log(response);
        if (response.data.status === true) {
          setProgramId(response.data.data[0].programs_id);
          setActivityUpadteImage(response.data.data[0].post_image);
          setActivityUpadteDocument(response.data.data[0].document);
          setTemplate(response.data.data[0].template_id);
          setPostContent(response.data.data[0].post_content);
          // setPostContent(
          //   removeStylesFromHTML(response.data.data[0].post_content.replace(/\r?\n/g, '<br />'))
          // );
          // const blocksFromHTML = htmlToDraft((response.data.data[0].post_content));
          // const blocksFromHTML = htmlToDraft(
          //   removeStylesFromHTML(response.data.data[0].post_content.replace(/\r?\n/g, '<br />'))
          // );
          // const contentState = ContentState.createFromBlockArray(
          //   blocksFromHTML.contentBlocks,
          //   blocksFromHTML.entityMap
          // );
          // const newEditorState = EditorState.createWithContent(contentState);
          // setEditorState(newEditorState);

          // const contentBlock = htmlToDraft((response.data.data[0].post_content));
          // const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          // const editorState = EditorState.createWithContent(contentState);
          // setEditorState(editorState);
          // const contentState = ContentState.createFromText((response.data.data[0].post_content));
          // const newEditorState = EditorState.createWithContent(contentState);
          // setEditorState(newEditorState);
          setPostTitle(response.data.data[0].post_title);
          // setEditorState(EditorState.createEmpty());
          setActivityOpenModal(true);
        }
      });
    } catch (error) {
      console.error(
        'An error occurred while fetching program details:',
        error
      );
    }
  };

  const handleModalClose = () => {
    setProgramId(0);
    setSelectedImage([]);
    setSelectedDocument([]);
    setActivityId('');
    setActivityUpadteImage([]);
    setActivityUpadteDocument([]);
    setTemplate('');
    setPostContent('');
    setPostTitle('');
    setEditorState(EditorState.createEmpty());
    setActivityOpenModal(false);
  };

  // -----------------------09-07--------------------------
  const convertCommentsDate = isoString => {
    const date = new Date(isoString);
    const year = date.getUTCFullYear();
    const month = date.toLocaleString('default', {
      month: 'long',
      timeZone: 'UTC',
    });
    const day = date.getUTCDate();
    let hour = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12; // Convert hour to 12-hour format
    return `${month} ${day}, ${year} | ${hour}:${minutes} ${ampm}`;
  };

  // function convertCommentsDate(commentDate) {
  //     return format(commentDate.setHours(commentDate.getUTCHours(), commentDate.getUTCMinutes(), commentDate.getUTCSeconds()), "MMMM d, yyyy '|' hh:mm a", { timeZone: 'Asia/Kolkata' })
  // }

  function convertPostDate(postDate) {
    const parsedDate = parse(postDate, 'yyyy-MM-dd h:mm:ss a', new Date());
    return format(parsedDate, 'MMMM d, yyyy | hh:mm a', {
      timeZone: 'Asia/Kolkata',
    });
  }

  // -----------17-07-------------------------

  const [isRecycleBinVisible, setRecycleBinVisible] = useState(false);
  const [activityDeleteId, setActivityDeleteId] = useState('');
  const handleRecycleBinClick = activityId => {
    setActivityDeleteId(activityId);
    setRecycleBinVisible(true);
  };

  const handleRecycleBinClose = () => {
    setRecycleBinVisible(false);
  };

  const handleRecycleBin = async () => {
    const url = `${baseUrl}/api/post_delete`;
    const data = {
      id: activityDeleteId,
      updated_by: localStorage.getItem('loginUserId'),
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: localStorage.getItem('access_token'),
    };
    try {
      const response = await axios.post(url, data, { headers });
      console.log(response);
      if (response.data.status === true) {
        setRecycleBinVisible(false);
        setSnackbarMessage(response.data.message);
        setMessageType('success');
        handleSnackbarOpen();
        fetchActivity();
      } else {
        setSnackbarMessage(response.data.message);
        setMessageType('error');
        handleSnackbarOpen();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ------------------15-07------------------
  const activityFileType = fileName => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (extension === 'pdf') {
      return 'pdf';
    } else if (['doc', 'docx'].includes(extension)) {
      return 'doc';
    }
  };


  // ---------------------23-07--------------
  const fetchActivityDetails = async () => {
    axios({
      method: 'post',
      url: baseUrl + '/api/post_details',
      data: {
        id: activityId,
        user_id: localStorage.getItem('loginUserId'),
        school_id: localStorage.getItem('schoolId'),
        admin: 1,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('access_token'),
      },
    }).then(response => {
      console.log(response);
      if (response.data.status === true) {
        setActivity(response.data.data[0]);
        console.log(response);
      }
    });
  };

  // -------------25-07-------------------
  const toggleComments = index => {
    $('.post-comments-inr').each(function () {
      if ($(this).attr('id') !== `post-comments-inr-${index}`) {
        $(this).slideUp();
      }
    });
    $(`#post-comments-inr-${index}`).slideToggle();
  };

  const [expandedPosts, setExpandedPosts] = useState({});

  const handleSeeMoreClick = index => {
    setExpandedPosts(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const [refreshNotification, setRefreshNotification] = useState(false);

  // ------------------------------30-08--------------------------
  const [adminData, setAdminData] = useState({});
  const fetchAdminData = async () => {
    try {
      const response = await axios.post(
        baseUrl + '/api/get_staff',
        {
          id: localStorage.getItem('loginUserId'),
          school_id: localStorage.getItem('schoolId'),
        },
        {
          headers: {
            Authorization: localStorage.getItem('access_token'),
          },
        }
      );
      console.log(response.data.data[0]);
      setAdminData(response.data.data[0] || {});
    } catch (error) { }
  };

  // -----23-09------
  const activityRef = useRef(null);
  const targetElementRef = useRef(null);
  const unhighlightElementRef = useRef(null);
  const [highlightPostId, setHighlightPostId] = useState(null);
  const [highlightPost, setHighlightPost] = useState(false);

  const handleHighlightPost = (status) => {
    setHighlightPost(status);
  };

  useEffect(() => {
    if (highlightPost) {
      if (targetElementRef.current) {
        targetElementRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [highlightPost]);

  useEffect(() => {
    const handleClick = (event) => {
      if (activityRef.current && activityRef.current.contains(event.target)) {
        setHighlightPost(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const [changeMessageVisible, setChangeMessageVisible] = useState(false);
  const handleChangeMessage = () => {
    changeTemplateData(templateChangeData);
    setChangeMessageVisible(false);
  };

  const handleChangeMessageClose = () => {
    setChangeMessageVisible(false);
  };
  // Regular expression to detect URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Regular expression to detect emails
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;
  const convertTextToLinks = (text) => {
    // Replace URLs with <a> tags
    let replacedText = text.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });

    // Replace emails with <a> mailto links
    replacedText = replacedText.replace(emailRegex, (email) => {
      return `<a href="mailto:${email}">${email}</a>`;
    });

    // Replace \r\n or \n with <br/>
    replacedText = replacedText.replace(/\r\n|\n/g, '<br/>');

    return replacedText;
  };

  useEffect(() => {
    fetchAdminData();
    fetchProgramsData();
    fetchPrograms();
    fetchTemplateData();
    fetchActivity();
    fetchTeachersData();
    // $(document).on('click', '.post-images', function () {
    //     $('.modal-lightbox').addClass('visible');
    // })
    // $(document).on('click', '.modal-lightbox .close', function () {
    //     $('.modal-lightbox').removeClass('visible');
    // });
    $('#search-input').keyup(function () {
      var searchTerm = $(this).val().toLowerCase();

      $('.search-items li').each(function () {
        var itemText = $(this).text().toLowerCase();

        if (itemText.indexOf(searchTerm) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });
    });
    $(document).on('click', '#new-template', function () {
      $('.overlay').addClass('visible');
      $('.popup-modal-container#create-template-popup').addClass('visible');
    });
    // $('.act-announce-inp input').on('click', function () {
    //     $('.announce-post-inr').slideDown();
    // });
  }, []);

  return (
    <section className="activity_page">
      <Snackbar className={`snack_total ${messageType === 'success' ? "snackbar_success" : "snackbar_failure"}`}
        anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <div>
                {messageType === 'success' ?
                  <span class="icon-List_Present" style={{ fontSize: " 21px;" }}>
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </span> :
                  <span class="icon-List_Absent" style={{ fontSize: " 21px;" }}>
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                    <span class="path6"></span>
                  </span>
                }
              </div>
              {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
            </ListItemIcon>
            <div className="success_cuc">
              <p> {snackbarMessage} </p>
            </div>
          </div>
        }
        // ContentProps={{
        //     sx: {
        //         border: "1px solid",
        //         opacity: "1",
        //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
        //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
        //         width: 'auto',
        //         minWidth:'auto'
        //     }
        // }}
        autoHideDuration={3000}
      />
      <Modal
        open={openActivityModal}
        // onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ padding: '20px', paddingBottom: '0px' }}>
            <div className="flexc justifySp">
              <div className="sp-tt ft-14 ft-wt600 head_cus_cal">
                {activityId !== '' ? 'Edit' : 'Create'} Post
              </div>
              <div className="create_postbox_div"
                style={{
                  position: 'absolute',
                  right: '-10px',
                  float: 'right',
                  top: '-10px',
                  cursor: 'pointer',
                  fontSize: '30px',
                }}
                onClick={() => handleModalClose()}
              >
                {/* <img
                  src={close}
                  style={{ height: '30px', width: '30px', paddingTop: '2px' }}
                  alt="close-icon"
                /> */}
                <span class="icon-List_Leave">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                </span>
              </div>
            </div>
            <div style={{ marginTop: '15px' }}>
              <div className="act-announce-inp flexc act_input">
                <div className="annonce-pers-left flexc create-post">
                  {/* <img src={avatar1} alt="avatar" /> */}
                  {adminData && adminData['profile_image'] === null
                    ?
                    // <ActivityLogoComponent name={adminData['first_name']} bgcolor={adminData['staff_color']}/>
                    adminData['first_name'] && <span className="text-xsmall act_create_post_avat mr-rt10"
                      style={{ backgroundColor: adminData['staff_color'] }}>
                      {adminData['first_name'].slice(0, 1).toUpperCase()}
                    </span>
                    : <div className="profile-pic create_post_imagessize">
                      <img
                        // src={baseUrl + "/api/" + localStorage.getItem("loginUserProfile")}
                        src={baseUrl + '/api/' + adminData['profile_image']}
                        className="mr-rt10"
                        alt={adminData['first_name']}
                      />
                    </div>}
                  {/* <span className="text-xsmall first_com_inside mr-rt10">{localStorage.getItem("loginUserName").slice(0, 1).toUpperCase()}</span> */}
                  <div className="annonce-pers-left-cont text-cap">
                    {(selectedProgram || programId === 0) &&
                      <p style={{ fontSize: '13px' }}>
                        <div style={{ display: 'flex' }}>
                          <span className="ft-wt700" style={{ color: '#000000' }}>
                            {localStorage.getItem('loginUserName')}
                          </span>
                          <span
                            style={{
                              color: '#000000',
                              fontWeight: '600',
                              paddingRight: '3px',
                              paddingLeft: '3px',
                            }}
                          >
                            |
                          </span>
                          {
                            (
                              <span 
                                className="ft-wt700" 
                                style={{ color: '#000000' }}
                                data-tooltip-id="program-tooltip"
                                data-tooltip-content={selectedProgram && selectedProgram.program_name}
                                data-tooltip-place="top"
                              >
                            {selectedProgram
                                ? (selectedProgram.program_name.length <= 20) ? selectedProgram.program_name :`${selectedProgram.program_name.slice(0, 20)}...`
                                : programId === 0 ? 'All Program' : ''}
                              </span>
                            )
                          }
                          {selectedProgram && selectedProgram.program_name.length > 20 && (
                            <Tooltip id="program-tooltip" place="bottom" />
                          )}
                        </div>
                      </p>}
                    <span
                      className="post-re-tm ft-13 sp-post-bt "
                      style={{ marginTop: '3px' }}
                    >
                      <div className="act_program por_div_cus seqlect_div_cus">
                        <Dropdown
                          label={
                            <span
                              style={{
                                color: '#707070',
                                whiteSpace: 'nowrap',
                                fontSize: '0.8825em',
                                padding: '0px',
                              }}
                            >
                              {selectedProgram
                                ? selectedProgram.program_name.slice(0, 20)
                                : programId === 0
                                  ? 'All Program'
                                  : 'Select Program'}
                            </span>
                          }
                          style={{ width: '100%' }}
                        >
                          <Dropdown.Item
                            className={`${programId === 0 ? 'selected-item dd_select' : ''}`}
                            //    className={programId === 0 ? "dd_select" : null}
                            icon={programId === 0 ? HiCheck : null}
                            onClick={() => changeProgramsData(0)}
                            key={0}
                          >
                            All Program
                          </Dropdown.Item>
                          {programs.map(item => (
                            <Dropdown.Item
                              className={`${selectedProgram && selectedProgram.id === item.id ? 'selected-item dd_select' : ''}`}
                              //  className={selectedProgram && selectedProgram.id === item.id ? "dd_select" : null}
                              icon={
                                selectedProgram &&
                                  selectedProgram.id === item.id
                                  ? HiCheck
                                  : null
                              }
                              onClick={() => changeProgramsData(item.id)}
                              key={item.id}
                            >
                            <div                             
                              data-tooltip-id={`program-tooltip-${item.id}`}
                              data-tooltip-content={item.program_name}
                              data-tooltip-place="bottom"
                              >
                              {
                                (item.program_name.length <= 20) ? item.program_name : `${item.program_name.slice(0, 20)}...`
                              }
                              </div>
                              {item.program_name.length > 20 && <Tooltip id={`program-tooltip-${item.id}`} />}
                              {/* {item.program_name} */}
                            </Dropdown.Item>
                          ))}
                        </Dropdown>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="titdes_scrool">
                <div className="act-announce-inp flexc act_input editorHeaderClassName ft-13 font_in_text activit_pa_in">
                  <input
                    style={{ border: '0px', marginTop: '15px' }}
                    type="text"
                    placeholder="Enter Title"
                    value={postTitle}
                    onChange={ev => setPostTitle(ev.target.value)}
                  />
                </div>
                <textarea
                  className="sp-field-input editorClassName text_inline_pad"
                  // rows={maxLines}
                  // onKeyDown={limitLines}
                  value={postContent}
                  onChange={ev => setPostContent(ev.target.value)}
                  placeholder="Add Descriptions..."
                />
                {/* <Editor
                  editorState={editorState}
                  toolbarHidden
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                  placeholder="Add Descriptions..."
                /> */}
                <div
                  className="sp-profile-rt flexc"
                  style={{ marginTop: '15px' }}
                >
                  {activityUpadteImage.length > 0 &&
                    activityUpadteImage.map((image, index) => (
                      <div
                        key={index}
                        className=" flexc"
                        style={{ position: 'relative', borderRadius: '10%' }}
                      >
                        <div className="sp-accbox1">
                          <img
                            src={baseUrl + '/api/' + image}
                            alt={`picked-image-${index}`}
                            style={{ borderRadius: '10%' }}
                          // width='90px'
                          // height='90px'
                          />
                        </div>
                        <span
                          onClick={() => handleRemoveActivityUpadteImage(index, image)}
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            cursor: 'pointer',
                            borderRadius: '50%',
                          }}
                        >
                          <div className="img_20 im_sm_siz">
                            {/* <img src={imgClose} alt="remove-icon" /> */}
                            <span class="icon-List_Leave"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                          </div>
                        </span>
                      </div>
                    ))}
                  {selectedImage.length > 0 &&
                    selectedImage.map((image, index) => (
                      <div
                        key={index}
                        className=" flexc"
                        style={{ position: 'relative', borderRadius: '10%' }}
                      >
                        <div className="sp-accbox1">
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`picked-image-${index}`}
                            style={{ borderRadius: '10%' }}
                          // width='90px'
                          // height='90px'
                          />
                        </div>
                        <span
                          onClick={() => handleRemoveImage(index)}
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            cursor: 'pointer',
                            borderRadius: '50%',
                          }}
                        >
                          <div className="img_20 im_sm_siz">
                            <span class="icon-List_Leave"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                            {/* <img src={imgClose} alt="remove-icon" /> */}
                          </div>
                        </span>
                      </div>
                    ))}
                </div>
                <div
                  className="sp-profile-rt"
                  style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                  {activityUpadteDocument.length > 0 &&
                    activityUpadteDocument.map((document, docIndex) => (
                      <div
                        key={docIndex}
                        className="announce-post-left dic_width"
                        style={{ marginTop: '10px', position: 'relative' }}
                      >
                        <div className="doc_pre">
                          <span
                            oclassName="flexc ft-13 sp-post-bt document_class"
                            style={{ display: 'flex' }}
                          >
                            {/* <i className="icon-Enquiries-Outline" /> */}
                            <img
                              src={
                                activityFileType(document.attachment_name) ===
                                  'doc'
                                  ? doc
                                  : imgPdf
                              }
                              alt=""
                              style={{ width: '14px', height: '14px' }}
                            />
                            <p>{document.attachment_name}</p>
                          </span>
                        </div>
                        <span
                          onClick={() =>
                            handleRemoveActivityUpadteDocument(
                              docIndex,
                              document.attachment_file,
                              document.attachment_name
                            )}
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            cursor: 'pointer',
                            borderRadius: '50%',
                          }}
                        >
                          <div className="img_20 im_sm_siz">
                            {/* <img src={imgClose} alt="remove-icon" /> */}
                            <span class="icon-List_Leave"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                          </div>
                        </span>
                      </div>
                    ))}
                  {selectedDocument.length > 0 &&
                    selectedDocument.map((document, docIndex) => (
                      <div
                        key={docIndex}
                        className="announce-post-left dic_width"
                        style={{ marginTop: '10px', position: 'relative' }}
                      >
                        <div className="doc_pre">
                          <span
                            oclassName="flexc ft-13 sp-post-bt document_class"
                            style={{ display: 'flex' }}
                          >
                            {/* <i className="icon-Enquiries-Outline" /> */}
                            <img
                              src={
                                activityFileType(document.name) === 'doc'
                                  ? doc
                                  : imgPdf
                              }
                              alt=""
                              style={{ width: '14px', height: '14px' }}
                            />
                            <p>{document.name}</p>
                          </span>
                        </div>
                        <span
                          onClick={() => handleRemoveDocument(docIndex)}
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            cursor: 'pointer',
                            borderRadius: '50%',
                          }}
                        >
                          <div className="img_20 im_sm_siz">
                            {/* <img src={imgClose} alt="remove-icon" /> */}
                            <span class="icon-List_Leave"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                          </div>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="announce-post-cont flexc justifySp activity_foot_div">
            <div className="annoce-poster-btm flexc image_hov_blur">
              <span className="ft-13">
                <div>
                  <input
                    type="file"
                    id="imageInput"
                    style={{ display: 'none', width: '100%' }}
                    accept=".jpg, .jpeg, .png, .gif"
                    multiple
                    onChange={handleImageChange}
                  />
                  <label htmlFor="imageInput">
                    <img
                      src={Image}
                      //   style={{ height: "15px", width: "15px",  cursor: "pointer" }}
                      alt="avatar"
                    />
                  </label>
                </div>
              </span>
              <span className="ft-13">
                <div>
                  <input
                    type="file"
                    id="documentInput"
                    style={{ display: 'none', width: '100%' }}
                    accept=".pdf, .doc, .docx"
                    multiple
                    onChange={handleDocumentChange}
                  />
                  <label className="ft-wt500 flexc" htmlFor="documentInput">
                    <img
                      src={adddoc}
                      //  style={{ height: "15px", width: "12px",  cursor: "pointer" }}
                      alt="avatar"
                    />
                  </label>
                </div>
              </span>
              {/* <span className="ft-13">
                <div>
                  <label className="ft-wt500 flexc">
                    <img
                      src={bell}
                      className="mr-rt10"
                      // style={{ height: "17px", width: "17px", }}
                      alt="avatar"
                    />
                  </label>
                </div>
              </span> */}
              <span className="flexc ft-13 sp-post-bt extra_mar_0">
                <div className="act_program event_drop_style">
                  <div className="activity_drop template_div_cus">
                    <Dropdown
                      label={
                        <span
                          style={{
                            color: '#707070',
                            whiteSpace: 'nowrap',
                            fontSize: '0.8825em',
                            padding: '0px',
                          }}
                        >
                          {selectedTemplate
                            ? selectedTemplate.template_name
                            : 'Select Template'}
                        </span>
                      }
                      style={{ width: '100%' }}
                    >
                      {templateData.map(item => (
                        <Dropdown.Item
                          className={`${selectedTemplate && selectedTemplate.id === item.id ? 'selected-item dd_select' : ''}`}
                          // className={selectedTemplate && selectedTemplate.id === item.id ? "dd_select" : null}
                          icon={
                            selectedTemplate && selectedTemplate.id === item.id
                              ? HiCheck
                              : null
                          }
                          onClick={() => changeTemplateData(item)}
                          key={item.id}
                        >
                          {item.template_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </div>
                </div>
              </span>
              <span
                className="flexc ft-13 sp-post-bt"
                style={{ cursor: 'pointer', backgroundColor: 'unset' }}
              />
            </div>
            <button
              className="flexc"
              disabled={addPostStatus}
              onClick={() => {
                if (addPostStatus === false) {
                  addPost();
                }
              }}
            >
              <img src={imgSend} alt="avatar" style={{ marginRight: '5px' }} />
              Post
            </button>
          </div>
        </Box>
      </Modal>
      <div className="outer-container" ref={activityRef}>
        <div className="overlay" />
        <div id="left-panel" className="sp-left-panel">
          <CommonSidebarHeader />
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="name_tag_side">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li
                    onClick={() => {
                      navigate('/dashboard');
                    }}
                  >
                    <span>
                      <i className="icon-Dashboard" />
                      <p>Dashboard</p>
                    </span>
                  </li>
                  <li className="selected">
                    <span>
                      <i className="icon-Activity" />
                      <p>Activity</p>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate('/calendar');
                    }}
                  >
                    <span>
                      <i className="icon-Timetable" />
                      <p>Calendar</p>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate('/staff_list');
                    }}
                  >
                    <span>
                      <i className="icon-Staff" />
                      <p>Staff</p>
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <i className="icon-Message" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                </ul>
                <h4
                  className="sp-title flexc justifySp"
                  onClick={() => handleOpenNewProgramPopupVisible("add")}
                >
                  Programs{' '}
                  <span id="add-programs" className="sp-plus">
                    +
                  </span>
                </h4>
                <ul>
                  {programsLoading && programsData && programsData.length > 0
                    ? programsData.map(data => {
                      return (
                        <li
                          onClick={() => {
                            localStorage.setItem('programId', data.id);
                            localStorage.setItem(
                              'programName',
                              data.program_name
                            );
                            navigate('/student_list');
                          }}
                        >
                            <span
                                  className="ft-400 truncate"
                                  data-tooltip-id={`program-tooltip-${data.id}`}
                                  data-tooltip-content={data.program_name}
                                  data-tooltip-place="bottom"
                                >
                                  <i className="icon-Program_1" />
                                  <p>
                                    {
                                      (data.program_name && data.program_name.length <= 25) ? data.program_name : `${data.program_name.slice(0, 25)}...`
                                    }
                                  </p>
                            </span>
                                {data.program_name.length > 25 && <Tooltip id={`program-tooltip-${data.id}`} />}
                        </li>
                      );
                    })
                    : <div className="data-loader extracss_load">
                      {/* <Loader
                                          type="spinner-cub"
                                          bgColor="#4D6EE3"
                                          color="#6B6F8C"
                                          title="Loading..."
                                          size={35}
                                        /> */}
                      <WindmillSpinner
                        loading={'loading'}
                        color="#4d6ee3d6"
                        size={30}
                      />
                      <p>Loading...</p>
                    </div>}
                </ul>
              </div>
            </div>

            {/* <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        <div className="noti_sdi">
                                            <img src={gift} alt="" style={{ height: "18px", width: "18px" }} />
                                            <p>Notifications</p>
                                        </div>

                                    </span>
                                </li>
                                <li>
                                    <span style={{ display: "block" }}>
                                        <div className="noti_tot">
                                            <div className="noti_sdi">
                                                <img src={upar} alt="" style={{ height: "18px", width: "18px" }} />
                                                <p>Upgrade Pro</p>
                                            </div>
                                            <img src={ques} alt="" style={{ height: "18px", width: "18px" }} />
                                        </div>
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        <p>Log Out</p>
                                    </span>
                                </li>
                            </ul>
                        </div> */}
          </div>
        </div>
        {/* my code */}
        <div className="sp-right-panel">
          <div className="activity-container">
            <div className="activity-inr">
              <div
                // className="act-announce-sh"
                className={`act-announce-sh ${openActivityModal ? 'cre_post_border' : ''}`}
              >
                <div className="act-announce-inp flexc act_input">
                  <div className="annonce-pers-left flexc ">
                    {
                      adminData && adminData['profile_image'] !== null
                        ? <div className="profile-pic">
                          <img
                            src={
                              baseUrl +
                              '/api/' +
                              adminData['profile_image']
                            }
                            className="mr-rt10"
                            alt={adminData['first_name']}
                          />
                        </div> :
                        // <ActivityLogoComponent name={adminData['first_name']} bgcolor={adminData['staff_color']} />
                        adminData['first_name'] && <span className="text-xsmall acti_post_av mr-rt10"
                          style={{ backgroundColor: adminData['staff_color'] }}
                        >
                          {adminData['first_name'].slice(0, 1).toUpperCase()}
                        </span>
                    }
                    {/* <span className="text-xsmall top_name_single mr-rt10">{localStorage.getItem("loginUserName").slice(0, 1).toUpperCase()}</span> */}
                    <div className="annonce-pers-left-cont">
                      <p onClick={() => handleModalOpen('')}>
                        What's an announcement today?
                      </p>
                      {/* <input
                        style={{ border: "0px" }}
                        type="text"
                        placeholder="Enter Title"
                        onClick={handleModalOpen}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="sp-announcement pos_padd_dis">
                {activitysLoading && activityData && activityData.length > 0
                  ? activityData.map((data, index) => {
                    // const textContent = extractTextFromHTML(data.post_content);
                    // const isContentLong = wordCount(textContent) > 20;
                    // const isExpanded = expandedPosts[index];
                    const textContent = extractTextFromHTML(
                      data.post_content
                    );
                    const lineCount = html => {
                      const lines = html
                        .replace(/<br>/gi, '\n')
                        .split(/<\/p>|<br>/i)
                        .filter(line => line.trim() !== '');
                      return lines.length;
                    };

                    const isContentLong =
                      lineCount(data.post_content) > 5 ||
                      wordCount(textContent) > 60;
                    const isExpanded = expandedPosts[index];
                    return (
                      <div
                        ref={data.id === parseInt(highlightPostId) ? targetElementRef : unhighlightElementRef}
                        key={index}
                        // id={`announcement-posts-${index}`}
                        className={`announcement-posts act-announce-sh marg_view_style ${(highlightPost === true && data.id === parseInt(highlightPostId)) ? "highlight_post" : ""}`}
                      >
                        <div className="annonce-pers flexc justifySp pos_abou_pro ">
                          <div className="annonce-pers-left flexc ">

                            {(data.userprofile !== null && data.userprofile !== "")
                              ? <img
                                src={baseUrl + '/api/' + data.userprofile}
                                className="mr-rt10"
                                alt={data.user} />
                              // /> :<ActivityLogoComponent name={data.user} bgcolor={data.usercolor} />
                              : <span className="text-xsmall acti_post_av mr-rt10"
                                style={{ backgroundColor: data.usercolor }}
                              >
                                {data.user.slice(0, 1).toUpperCase()}
                              </span>
                            }

                            {/* <img
                              src={avatar1}
                              className="mr-rt10"
                              width={38}
                              height={38}
                              alt="avatar"
                            /> */}
                            <div className="annonce-pers-left-cont text-cap">
                              <p>
                                <span className="ft-wt700 user_name_style">
                                  {data.user}
                                </span>
                                <span className="sp-crt-dot " />
                                <span className="sub_name_style">
                                  {data.programs_id === 0
                                    ? 'All Program'
                                    : data.program_name}
                                </span>
                              </p>
                              {/* <span className="post-re-tm ft-13 date_name_style">{moment(data.created_on, 'YYYY-MM-DD hh:mm:ss A').format('MMMM DD, YYYY')}</span> */}
                              <span className="post-re-tm ft-13 date_name_style">
                                {convertPostDate(data.created_on)}
                              </span>
                            </div>
                          </div>
                          <span className="pos_fix_edit">
                            {/* <i className="icon-More-Icon" /> */}
                            <div className="svg_hide_drop">
                              <Dropdown
                                label={
                                  <span
                                    style={{
                                      color: '#1B252F',
                                      padding: '0px',
                                    }}
                                  >
                                    <i className="icon-More active_ic_more" />
                                  </span>
                                }
                                style={{ width: '100%' }}
                              >
                                <Dropdown.Item
                                  className="pad_progr1"
                                  onClick={() => handleModalOpen(data.id)}
                                >
                                  <i className="icon-Student_Edit" /> &nbsp; &nbsp;
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="pad_progr1"
                                  onClick={() =>
                                    handleRecycleBinClick(data.id)}
                                >
                                  <i className="fa-regular fa-trash-can" /> &nbsp; &nbsp;
                                  Delete
                                </Dropdown.Item>
                              </Dropdown>
                            </div>
                          </span>
                        </div>
                        <div className="annoce-poster-inr">
                          <div className="ft-wt700 post_view_heading">
                            {data.post_title}
                          </div>
                          {wordCount(textContent) !== 0 &&
                            <div className="mt-4">
                              <div
                                className="content content_style_view"
                                dangerouslySetInnerHTML={{
                                  __html: removeStylesFromHTML(
                                    // data.post_content.replace(/\r?\n/g, '<br />')
                                    convertTextToLinks(data.post_content)

                                  ),
                                }}
                                style={!isExpanded ? contentStyle : {}}
                                ref={contentRef}
                              />
                              {isContentLong &&
                                !isExpanded &&
                                <div
                                  className="ft-13 mt-4 ft-wt600 seemore_style_view"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleSeeMoreClick(index)}
                                >
                                  See more...
                                </div>}
                              {/* {isExpanded && (
                                  <div className="ft-13 mt-4 ft-wt600 seemore_style_view" style={{ cursor: "pointer" }} onClick={() => handleSeeMoreClick(index)}>See less</div>
                              )} */}
                            </div>}
                        </div>
                        {/* <div className="annoce-poster-inr">
                              <div className="ft-wt700 post_view_heading">{data.post_title}</div>
                              {wordCount(textContent) !== 0 &&
                                  <div className="mt-4">
                                      <div
                                          className="content content_style_view"
                                          dangerouslySetInnerHTML={{ __html: data.post_content }}
                                          style={contentStyle}
                                          ref={contentRef}
                                      />
                                      {isContentLong && (
                                          <div className="ft-13 mt-4 ft-wt600 seemore_style_view" style={{ cursor: "pointer" }} onClick={() => { setComment(''); openModal(data); }}>See more...</div>
                                      )}
                                  </div>
                              }
                        </div> */}
                        {data.post_image &&
                          data.post_image.length > 0 &&
                          <div className="post-images cp mt-10">

                            <div>
                              {data.post_image.length === 1 &&
                                <div className="image-row flexc two-column justifySp relative">
                                  {data.post_image
                                    .slice(0, 1)
                                    .map((image, index) => (
                                      <div
                                        style={{
                                          width: '33%',
                                          height: '300Px',
                                          overflow: 'hidden',
                                          borderRadius: '10px',
                                        }}
                                        onClick={() => {
                                          setComment('');
                                          setSliderDefaultIndex(index);
                                          openModal(data);
                                        }}
                                      >
                                        <img
                                          key={index}
                                          src={baseUrl + '/api/' + image}
                                          alt={`Image ${index + 1}`}
                                          style={{
                                            height: '300px',
                                            width: '100%',
                                            maxWidth: 'unset',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                          }}
                                        />
                                      </div>
                                    ))}
                                </div>}
                              {data.post_image.length === 2 &&
                                <div className="image-row flexc two-column justifySp relative">
                                  {data.post_image
                                    .slice(0, 2)
                                    .map((image, index) => (
                                      <div
                                        style={{
                                          width: '50%',
                                          height: '200Px',
                                          overflow: 'hidden',
                                          borderRadius: '10px',
                                        }}
                                        onClick={() => {
                                          setComment('');
                                          setSliderDefaultIndex(index);
                                          openModal(data);
                                        }}
                                      >
                                        <img
                                          key={index}
                                          src={baseUrl + '/api/' + image}
                                          alt={`Image ${index + 1}`}
                                          style={{
                                            height: '200px',
                                            width: '100%',
                                            maxWidth: 'unset',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                          }}
                                        />
                                      </div>
                                    ))}
                                </div>}
                              {data.post_image.length === 3 &&
                                <div className="image-row flexc three-column justifySp relative">
                                  {data.post_image
                                    .slice(0, 3)
                                    .map((image, index) => (
                                      <div
                                        style={{
                                          width: '33%',
                                          height: '200Px',
                                          overflow: 'hidden',
                                          borderRadius: '10px',
                                        }}
                                        onClick={() => {
                                          setComment('');
                                          setSliderDefaultIndex(index);
                                          openModal(data);
                                        }}
                                      >
                                        <img
                                          key={index}
                                          src={baseUrl + '/api/' + image}
                                          alt={`Image ${index + 1}`}
                                          style={{
                                            height: '200px',
                                            width: '100%',
                                            maxWidth: 'unset',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                          }}
                                        />
                                      </div>
                                    ))}
                                </div>}
                            </div>

                            {data.post_image.length > 3 &&
                              <div>
                                <div className="image-row flexc three-column justifySp relative">
                                  {data.post_image
                                    .slice(0, 3)
                                    .map((image, index) => (
                                      <div
                                        style={{
                                          width: '33%',
                                          height: '200Px',
                                          overflow: 'hidden',
                                          borderRadius: '10px',
                                        }}
                                        onClick={() => {
                                          setComment('');
                                          setSliderDefaultIndex(index);
                                          openModal(data);
                                        }}
                                      >
                                        <img
                                          key={index}
                                          src={baseUrl + '/api/' + image}
                                          alt={`Image ${index + 1}`}
                                          style={{
                                            height: '200px',
                                            width: '100%',
                                            maxWidth: 'unset',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                          }}
                                        />
                                      </div>
                                    ))}
                                </div>

                                <div className="image-row flexc two-column justifySp relative">
                                  {data.post_image
                                    .slice(3, 4)
                                    .map((image, index) => (
                                      <div
                                        style={{
                                          width: '50%',
                                          height: '200Px',
                                          overflow: 'hidden',
                                          borderRadius: '10px',
                                        }}
                                        onClick={() => {
                                          setComment('');
                                          setSliderDefaultIndex(3);
                                          openModal(data);
                                        }}
                                      >
                                        <img
                                          key={index}
                                          src={baseUrl + '/api/' + image}
                                          alt={`Image ${4}`}
                                          style={{
                                            height: '200px',
                                            width: '100%',
                                            maxWidth: 'unset',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                          }}
                                        />
                                      </div>
                                    ))}
                                  {data.post_image.length > 4 &&
                                    data.post_image
                                      .slice(4, 5)
                                      .map((image, index) => (
                                        <div
                                          style={{
                                            width: '50%',
                                            height: '200Px',
                                            overflow: 'hidden',
                                            borderRadius: '10px',
                                            position: 'relative',
                                          }}
                                          onClick={() => {
                                            setComment('');
                                            setSliderDefaultIndex(4);
                                            openModal(data);
                                          }}
                                        >
                                          {/* <div> */}
                                          <img
                                            key={index}
                                            src={baseUrl + '/api/' + image}
                                            alt={`Image ${5}`}
                                            className={`${data.post_image.length > 5 && 'blur_image_style'}`}
                                            style={{
                                              height: '200px',
                                              width: '100%',
                                              maxWidth: 'unset',
                                              objectFit: 'cover',
                                              objectPosition: 'center',
                                            }}
                                          />

                                          {data.post_image.length > 5 &&
                                            <div className="more-images absolute">
                                              <span className="more_num ft-17">
                                                +{data.post_image.length - 5}
                                              </span>
                                            </div>}

                                          {/* </div> */}
                                        </div>
                                      ))}
                                </div>
                              </div>}

                          </div>}

                        {data.document &&
                          data.document.length > 0 &&
                          data.document.map((documentData, docIndex) => (
                            <div
                              key={docIndex}
                              className="announce-post-left dic_width mt-10"
                            >
                              <span
                                onClick={() => {
                                  downloadDocument(
                                    documentData.attachment_file,
                                    documentData.attachment_name
                                  );
                                }}
                                className="flexc ft-13 sp-post-bt document_class"
                                style={{ cursor: 'pointer' }}
                              >
                                {/* <i className="icon-Enquiries-Outline" /> */}
                                <img
                                  src={
                                    activityFileType(
                                      documentData.attachment_name
                                    ) === 'doc'
                                      ? doc
                                      : imgPdf
                                  }
                                  alt=""
                                  style={{ width: '14px', height: '14px' }}
                                />
                                <p>{documentData.attachment_name}</p>
                              </span>
                            </div>
                          ))}
                        <div className="annoce-poster-btm flexc mt-10">
                          <span
                            className="flexc"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleSendLike(
                                data.id,
                                !data.like ? 'add' : 'delete'
                              );
                            }}
                          >
                            <div className="act_like">
                              <i
                                className={`${!data.like ? 'icon-Like' : 'icon-Like clr_whi'}`}
                                aria-hidden="true"
                              />
                            </div>
                            <p
                              className="like_com_style"
                              style={{ color: data.like && '#F14B4B' }}
                            >
                              {' '}{data.likes || 0}
                            </p>
                          </span>
                          <span
                            className="flexc "
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setComment('');
                              toggleComments(index);
                            }}
                          >
                            <i
                              className={`icon-Message ${data.comments && 'mes_onclick'}`}
                            />
                            <p
                              className="like_com_style"
                              style={{ color: data.comments && '#F14B4B' }}
                            >
                              {' '}{data.comments || 0}
                            </p>
                          </span>
                        </div>
                        <div
                          id={`post-comments-inr-${index}`}
                          className="post-comments-inr"
                          style={{ display: 'none' }}
                        >
                          <div className="comments-box">
                            {data.comments_data.length !== 0 &&
                              <div
                                className={
                                  data.comments_data.length > 1 && 'comments'
                                }
                              >
                                {data.comments_data.map(
                                  (comment, commentIndex) => (
                                    <div
                                      className=" come_border pb-2.5"
                                      key={commentIndex}
                                    >
                                      <div className="annonce-pers-left flexc comment-img">

                                        {comment.profile_image !== null
                                          ? <img
                                            src={
                                              baseUrl +
                                              '/api/' +
                                              comment.profile_image
                                            }
                                            className="mr-rt10"
                                            alt={data.user}
                                          /> :
                                          <span className="text-xsmall acti_post_av mr-rt10"
                                            style={{ backgroundColor: comment.usercolor }} >
                                            {comment.user_name.slice(0, 1).toUpperCase()}
                                          </span>
                                          // <ActivityLogoComponent name={comment.user_name} bgcolor={comment.usercolor} />
                                          // : <span className="extra_rose">
                                          //   {comment.user_name
                                          //     .slice(0, 1)
                                          //     .toUpperCase()}
                                          // </span>
                                        }
                                        {/* <span className="extra_rose">{comment.user_name.slice(0, 1).toUpperCase()}</span> */}
                                        <div className="annonce-pers-left-cont text-cap">
                                          <p className="ft-13">
                                            <span className="ft-wt600">
                                              {comment.user_name}
                                            </span>
                                          </p>
                                          <span className="post-re-tm ft-12">
                                            {convertCommentsDate(
                                              comment.created_on
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <p className="ft-13 leading-normal mt-4 come_box_div">
                                        {/* <textarea
                                             className="sp-field-input fixed-height-textarea"
                                             value={comment.comment}
                                             readOnly={true}
                                         >
                                         </textarea> */}
                                        <div dangerouslySetInnerHTML={{ __html: comment.comment.replace(/\r?\n/g, '<br />') }} />
                                        {/* {comment.comment} */}
                                      </p>
                                    </div>
                                  )
                                )}
                              </div>}
                            <div className="sticky comment-type">
                              <div className="flexc flex-wrap extra_fl_comment">

                                {adminData && adminData['profile_image'] !== null
                                  ? <div className="profile-pic comment-img">
                                    <img
                                      src={
                                        baseUrl +
                                        '/api/' +
                                        adminData['profile_image']
                                      }
                                      className="mr-rt10"
                                      alt={adminData['first_name']}
                                    />
                                  </div>
                                  : adminData['first_name'] && <div className="profile-pic">
                                    {/* <ActivityLogoComponent name={adminData['first_name']} bgcolor={adminData['staff_color']} /> */}
                                    <span className="text-xsmall acti_post_av mr-rt10"
                                      style={{ backgroundColor: adminData['staff_color'] }} >
                                      {adminData['first_name'].slice(0, 1).toUpperCase()}
                                    </span>
                                  </div>}

                                <div className="box-comment ml-2  feed_comr">
                                  <div className="input-container pos_rela_view coment_box_feed">
                                    <textarea
                                      className="sp-field-input fixed-height-textarea"
                                      // rows={maxLines}
                                      // onKeyDown={limitLines}
                                      value={comment}
                                      onChange={ev => setComment(ev.target.value)}
                                      placeholder="Write a comment.."
                                    />
                                    <div
                                      className={`coment_icon_box ${!comment.trim() ? 'disabled' : ''}`}
                                    >
                                      <i
                                        className={`icon-Like-1 ${!comment.trim() ? 'disabled' : ''}`}
                                        aria-hidden="true"
                                        onClick={() => {
                                          if (
                                            comment.trim() !== '' &&
                                            commentAddStatus === false
                                          ) {
                                            handleSendComment(data.id);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : activitysLoading && activityData.length === 0
                    ?
                    <div className="Nodata_page nodata_center_pos">
                      <div className="nodata_activity_style">
                        <img src={nodatact} />
                        <h3>No Activity Post</h3>
                        <p>Click the button to create your first post!</p>
                        <button class="sp-btn-create" onClick={() => handleModalOpen('')}>Create Post</button>
                      </div>
                    </div>
                    // <center>
                    //   <div className="sp-title flex-3">No data found</div>
                    // </center>
                    : <div className="data-loader extracss_load">
                      <WindmillSpinner
                        loading={'loading'}
                        color="#4d6ee3d6"
                        size={30}
                      />
                      <p>Loading...</p>
                    </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonSidebarFooter
        refreshNotification={refreshNotification}
        setRefreshNotification={setRefreshNotification}
        setHighlightPost={handleHighlightPost}
      />
      {/* <CommonSidebarFooter refreshNotification={refreshNotification} /> */}
      {/* light-box */}
      {isModalVisible &&
        <div className="modal-lightbox flex visible">
          {
            <div className="img-container slider_style_cus flexc alignC justify-center relative">
              {activity.post_image.length > 1 &&
                <span className="left slide-arrow absolute left-10">
                  <button
                    className="active_col left-arr"
                    onClick={previousSlide}
                  >
                    <span className="icon-Onboard_Previous" />
                  </button>
                </span>}
              <div className="slide-container">
                {/* style={{ width: "90%", height: "100vh" }} */}
                <Fade
                  ref={slideRef}
                  arrows={false}
                  defaultIndex={sliderDefaultIndex}
                  //  autoplay={activity.post_image.length >= 2}
                  autoplay={false}
                >
                  {activity.post_image.map((slideImage, index) => (
                    <div className="each-slide">
                      <div className="slide-content">
                        <span>
                          <img
                            key={index}
                            src={baseUrl + '/api/' + slideImage}
                            alt={`Image ${index + 1}`}
                          // style={{ width: "500px" }}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                </Fade>
              </div>
              {activity.post_image.length > 1 &&
                <span className="right slide-arrow absolute right-10">
                  <button className="active_col right-arr" onClick={nextSlide}>
                    <span className="icon-Onboard_Next" />
                  </button>
                </span>}
            </div>
          }

          <div className="message-box p-6 overflow-y-auto h-screen py-0">
            <span
              className="close_activity  icon-Close icon-Dropdown_Close flexc cp ft-10"
              onClick={closeModal}
            />
            <div className="annonce-pers-left flexc mt-7 pad_sid_ac">
              {activity.userprofile !== null
                ? <img
                  src={baseUrl + '/api/' + activity.userprofile}
                  className="mr-rt10"
                  alt={activity.user}
                />
                // <ActivityLogoComponent name={activity.user} bgcolor={activity.usercolor} />
                : <span className="text-xsmall acti_post_av mr-rt10"
                  style={{ backgroundColor: activity.usercolor }}
                >
                  {activity.user.slice(0, 1).toUpperCase()}
                </span>
              }
              {/* <span className="text-xsmall first_com_top mr-rt10">{activity.user.slice(0, 1).toUpperCase()}</span> */}
              <div className="annonce-pers-left-cont text-cap">
                <p>
                  <span className="ft-wt700 user_name_style">
                    {activity.user}
                  </span>
                  <span className="sp-crt-dot" />
                  <span className="sub_name_style">
                    {activity.programs_id === 0
                      ? 'All Program'
                      : activity.program_name}
                  </span>
                </p>
                <span className="post-re-tm ft-13 date_name_style">
                  {convertPostDate(activity.created_on)}
                </span>
              </div>
            </div>
            <div className="annoce-poster-inr pad_sid_ac">
              <div className="ft-wt700 post_view_heading">
                {activity.post_title}
              </div>
              <div className="mt-4">
                <div
                  className="content content_style_view"
                  dangerouslySetInnerHTML={{
                    __html: removeStylesFromHTML(convertTextToLinks(activity.post_content)),
                  }}
                  // style={contentStyleinside}
                  ref={contentRef}
                />
              </div>
            </div>
            <div className="annoce-poster-btm flexc mt-10 pad_sid_ac pb-15">

              <span
                className="flexc"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleSendLike(
                    activity.id,
                    !activity.like ? 'add' : 'delete'
                  );
                }}
              >
                <div className="act_like">
                  <i
                    className={`${!activity.like ? 'icon-Like' : 'icon-Like clr_whi'}`}
                    aria-hidden="true"
                  />
                </div>
                <p
                  className="like_com_style"
                  style={{ color: activity.like && '#F14B4B' }}
                >
                  {' '}{activity.likes || 0}
                </p>
              </span>
              <span className="flexc">
                <i className={`icon-Message`} />
                <p className="like_com_style"> {activity.comments || 0}</p>
              </span>
            </div>
            <div className="comments-box pad_bot_6 pad_sid_ac bordertop-act">
              {activity.comments_data.map((comment, index) => (
                <div className="mt-5 pb-2.5">
                  <div className="annonce-pers-left flexc ">
                    {/* <span className="text-xsmall com_down_class ">
                      {comment.user_name.slice(0, 1).toUpperCase()}
                    </span> */}
                    {comment.profile_image !== null
                      ? <img
                        src={
                          baseUrl +
                          '/api/' +
                          comment.profile_image
                        }
                        className="mr-rt10"
                        alt={comment.user_name}
                      /> :
                      <span className="text-xsmall acti_post_av mr-rt10"
                        style={{ backgroundColor: comment.usercolor }} >
                        {comment.user_name.slice(0, 1).toUpperCase()}
                      </span>
                      // <ActivityLogoComponent name={comment.user_name} bgcolor={comment.usercolor} />

                    }
                    <div className="annonce-pers-left-cont text-cap">
                      <p className="ft-13">
                        <span className="ft-wt600 clr_black">{comment.user_name}</span>
                      </p>

                      <span className="post-re-tm ft-12">
                        {convertCommentsDate(comment.created_on)}
                        {/* {new Date(comment.created_on).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            })} */}
                      </span>
                    </div>
                  </div>
                  <p className="ft-13 leading-normal mt-4 come_box_div">
                    <div dangerouslySetInnerHTML={{ __html: comment.comment.replace(/\r?\n/g, '<br />') }} />
                    {/* {comment.comment} */}
                  </p>
                </div>
              ))}
              <div className="sticky comment-type coment_footer ">
                <div className="flexc flex-wrap extra_fl_comment comment-img">
                  {adminData && adminData['profile_image'] !== null
                    ? <div className="profile-pic">
                      <img
                        src={
                          baseUrl +
                          '/api/' +
                          adminData['profile_image']
                        }
                        className="mr-rt10"
                        alt={adminData['first_name']}
                      />
                    </div>
                    : adminData['first_name'] && <div>
                      {/* <ActivityLogoComponent name={adminData['first_name']} bgcolor={adminData['staff_color']} /> */}
                      <span className="profile-pic acti_post_av"
                        style={{ backgroundColor: adminData['staff_color'] }}>
                        {adminData['first_name'].slice(0, 1)
                          .toUpperCase()}
                      </span>
                    </div>}
                  {/* <div className="profile-pic coment_rose_clr">
                                        <a>
                                            <span>{localStorage.getItem("loginUserName").slice(0, 1).toUpperCase()}</span>
                                        </a>
                                    </div> */}
                  <div className="box-comment ml-2 feed_comr">
                    <div className="input-container pos_rela_view coment_box_feed">
                      {/* <input
                                                type="text"
                                                value={comment}
                                                onChange={ev => setComment(ev.target.value)}
                                                placeholder="Write a comment.."
                                                className="input-cmt ft-13"
                                            /> */}
                      <textarea
                        className="sp-field-input fixed-height-textarea"
                        // onKeyDown={limitLines}
                        value={comment}
                        onChange={ev => setComment(ev.target.value)}
                        placeholder="Write a comment.."
                      />
                      <div
                        className={`coment_icon_box ${!comment.trim() ? 'disabled' : ''}`}
                      >
                        <i
                          className={`icon-Like-1 ${!comment.trim() ? 'disabled' : ''}`}
                          aria-hidden="true"
                          onClick={() => {
                            if (comment.trim() !== '') {
                              handleSendComment(activity.id);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      {/* light-box */}
      <div className="popup-modal-container" id="create-template-popup">
        <div className="popup-wrap activ-pop">
          <h2 className="ft-wt700">Add New Template</h2>
          <div className="sp-pop-header">
            <div className="form-section-default flexc justifySp fwrap">
              <div className="field-label halfplus-field">
                <label>Template Name</label>
                <div className="field-value-holder margin_top10p">
                  <input className="sp-field-input" placeholder="Type name.." />
                </div>
              </div>
              <div className="field-label halfmin-field">
                <label>Template Type</label>
                <div
                  id="dropdownHelperButton"
                  className="btn-dropdown margin_top10p"
                  data-dropdown-toggle="dropdownHelper1"
                  data-dropdown-offset-distance={1}
                  data-dropdown-placement="bottom"
                >
                  <span className="flexc justifySp">
                    Select Type
                    <i className="icon-Down-Arrow" />
                  </span>
                </div>
                <div
                  className="btn-dropdown-fieldval hidden"
                  id="dropdownHelper1"
                >
                  <ul className="activity-temp">
                    <li className="selected">
                      <span className="icon-rating sp-red-theme1" />
                      Announcement
                    </li>
                    <li>
                      <span className="icon-rating sp-green-theme1" />
                      Event
                    </li>
                    <li>
                      <span className="icon-rating sp-red-theme1" />
                      Special Day
                    </li>
                    <li>
                      <span className="icon-rating sp-orange-theme1" />
                      Holiday
                    </li>
                    <li className="flexc new-doc mt-2">
                      <i className="icon-Enquiries-Outline sp-violet-theme1" />
                      Create New
                    </li>
                  </ul>
                </div>
              </div>
              <div className="field-label full-field">
                <div className="field-value-holder margin_top10p">
                  <div className="text-decrt">
                    <button className="flexc ft-13 text-styl">
                      Normal Text
                      <span className="arrow up-arr" />
                    </button>
                  </div>
                  <textarea
                    className="sp-field-input"
                    rows={10}
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sp-bottom-sticky">
            <button className="sp-btn-create" id="save-btn">
              Save
            </button>
            <button className="sp-btn-cancel">Cancel</button>
          </div>
        </div>
      </div>
      {/* Recycle Bin Modal */}
      {isRecycleBinVisible &&
        <div
          id="recycle-popup"
          tabIndex={-1}
          className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
        >
          <div className="relative w-full max-w-xs max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">
              {' '}  <div className="p-4 md:p-5 text-center">
                <div className="round-btn rounded inline-flex alignC justify-center">
                  <span class="icon-Recycle_Modal"></span>
                </div>
                <p className="ft-wt600 ft-16 mb-4 mt-3">Activity Delete</p>
                <h3 className="mb-5 font-normal text-sm">
                  Are you sure you want to delete this activity?
                  {/* Are you sure you want to move this activity to Recycle Bin? */}
                </h3>
                <button
                  onClick={handleRecycleBin}
                  type="button"
                  className="yes_button_sta_cus sp-red-color text-white  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md  inline-flex items-center px-5 py-2.5 text-center"
                >
                  Yes, Proceed
                </button>
                <button
                  onClick={handleRecycleBinClose}
                  type="button"
                  style={{
                    border: '1px solid #D7D9DD',
                  }}
                  className="can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                >
                  Cancel
                </button>

              </div>
            </div>
          </div>
        </div>}
      {/* change Message Modal */}
      {changeMessageVisible &&
        <div
          id="recycle-popup"
          tabIndex={-1}
          className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
        >
          <div className="relative w-full max-w-xs max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">
              {' '}  <div className="p-4 md:p-5 text-center">
                <div className="round-btn rounded inline-flex alignC justify-center">
                  {/* <img src={de} alt="" /> */}
                  <span class="icon-Recycle_Modal"></span>
                </div>
                <p className="ft-wt600 ft-16 mb-4 mt-3">Change Message?</p>
                <h3 className="mb-5 font-normal text-sm">
                  Switching template will discard the current message. Do you want to change?
                </h3>
                <button
                  onClick={handleChangeMessage}
                  type="button"
                  className="yes_button_sta_cus sp-red-color text-white  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md  inline-flex items-center px-5 py-2.5 text-center"
                >
                  Yes, Move
                </button>
                <button
                  onClick={handleChangeMessageClose}
                  type="button"
                  style={{
                    border: '1px solid #D7D9DD',
                  }}
                  className="can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                >
                  Cancel
                </button>

              </div>
            </div>
          </div>
        </div>}
      {/* Create New Program */}
      <ProgramAddComponent
        newProgramPopupVisible={newProgramPopupVisible}
        setNewProgramPopupVisible={setNewProgramPopupVisible}
        teachersData={teachersData}
        setProgramsData={setProgramsData}
        newProgramAddUpdateStatus={newProgramAddUpdateStatus}
        setNewProgramAddUpdateStatus={setNewProgramAddUpdateStatus}
      />
    </section>
  );
}
